/*
  Top Navbar
*/
@use './settings' as *;

.topbar {
  background: $gray-darker;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 0;

  .btn-link {
    color: $gray-light;

    &:hover {
      background: none;
      color: $green-brand;
    }
  }

  button,
  form {
    min-width: 60px;
  }

  button {
    &:hover,
    &:focus {
      .icon {
        fill: $green-brand !important;
      }
    }
  }

  .dropdown {
    ul {
      &:after {
        right: calc(1.222rem / 2); // icon width / 2
      }
    }
  }

  .logo {
    display: block;
    line-height: $navbar-height;
    padding: 0 $padding-sm;

    &:focus {
      background: $gray-darkest;
    }

    .icon {
      height: 1.444rem; // 26px
      vertical-align: -0.3em;
      width: 4.944rem; // 89px
    }
  }

  .list-inline {
    > li {
      margin: 0 !important;

      > a,
      button {
        color: $gray-light;
        display: inline-block;
        font-size: 1rem; // 16px
        line-height: $navbar-height;

        &:hover,
        &:focus,
        &.active {
          background: $gray-darkest !important;
          color: $green-brand !important;
        }

        .icon {
          height: 1.222rem; // 22px
          vertical-align: -0.3333rem; // 6px
          width: 1.222rem; // 22px
        }
      }

      > a:only-child {
        margin: 0;
        padding: 15px;
        text-decoration: none;
        line-height: 1.5em;
      }
      
      li {
        a,
        button {
          color: inherit;
          display: block;
          line-height: inherit;

          &:hover,
          &:focus {
            color: $green-brand;
          }
        }
      }
    }
  }
}
