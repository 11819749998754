@use './settings' as *;

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: $breakpoint-medium) {
  .medium-expand {
    flex: 1 1 0px;
  }

  .medium-flex-dir-row {
    flex-direction: row;
  }

  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }

  .medium-flex-dir-column {
    flex-direction: column;
  }

  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }

  .medium-flex-child-auto {
    flex: 1 1 auto;
  }

  .medium-flex-child-grow {
    flex: 1 0 auto;
  }

  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
}

@media print, screen and (min-width: $breakpoint-large) {
  .large-flex-dir-row {
    flex-direction: row;
  }

  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }

  .large-flex-dir-column {
    flex-direction: column;
  }

  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }

  .large-flex-child-auto {
    flex: 1 1 auto;
  }

  .large-flex-child-grow {
    flex: 1 0 auto;
  }

  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.align-left {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.align-right {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.align-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.align-justify {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.align-spaced {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-top {
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-self-top {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-bottom {
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-self-bottom {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-middle {
  -ms-flex-align: center;
  align-items: center;
}

.align-self-middle {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.align-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
