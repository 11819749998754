@use './settings' as *;

/* REGULAR LIST */
.list {
  font-size: 14px;
  line-height: 20px;
  width: 100%;

  .list-item {
    padding-left: $padding-sm;
    position: relative;
  }

  .list-item::before {
    content: "•";
    display: inline-block;
    font-size: 200%;
    left: 0;
    position: absolute;
    top: -2px;
  }

  .list-item.warning::before {
    color: $orange;
  }

  .list-item.danger::before {
    color: $red;
  }
}

/* CIRCLES LIST */
.list-circles {
  counter-reset: li;
  list-style: none;

  .list-circles-item {
    font: normal 17px/23px $sans-serif;
    margin: 0;
    padding: $padding-sm + 2 $padding-sm $padding-sm $padding-sm * 4;
    position: relative;
    border-radius: $corner-radius-medium;

    &:before {
      background: $gray-lightest;
      border-radius: 50%;
      counter-increment: li;
      content: counter(li);
      font-weight: 600;
      font-size: $padding-sm;
      height: $padding-md;
      left: $padding-sm;
      line-height: $padding-md;
      position: absolute;
      text-align: center;
      top: $padding-sm;
      width: $padding-md;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .list-circles-item-hover {
    background: $blue-lightest;
    cursor: pointer;
  }
}

.list-circles-black {
  .list-circles-item {
    &:before {
      background: $gray-darker;
      color: $white;
    }
  }
}

.list-circles-blue {
  .list-circles-item {
    &:before {
      background: $blue;
      color: $white;
    }
  }
}

.list-circles-blue-light {
  .list-circles-item {
    &:before {
      background: $blue-light;
      color: $gray-darker;
    }

    &:focus,
    &:hover {
      background: $blue-lightest;

      &:before {
        background: $blue;
        color: $white;
      }
    }
  }
}

.list-circles-inverse {
  color: $white;

  .list-circles-item {
    &:before {
      background: $blue;
      color: $white;
    }

    &:focus,
    &:hover {
      background: $gray-darker;

      &:before {
        background: $white;
        color: $blue;
      }
    }
  }

  .list-circles-item-hover {
    background: $gray-darker;
    cursor: pointer;
  }
}

/* DEFINITIONS LIST  */
.list-dfn {
  list-style: none;
  margin: 0;
  padding: 0;

  .list-dfn-item {
    border-bottom: 1px solid $gray-lighter;
    padding: $padding-sm;

    @media screen and (min-width: $breakpoint-small) {
      padding: $padding-md;
    }

    &:last-child {
      border-bottom: none;
    }

    .list-dfn-term {
      font: normal 700 28px/28px $sans-serif;
      margin-bottom: $padding-sm;
    }

    .list-dfn-description {
      font: normal 17px/22px $sans-serif;
      em {
        color: $gray;
        font-style: italic;
        margin-right: 0.5em;

        &:after {
          content: '–';
          margin-left: 0.5em;
        }
      }
    }
  }
}

/* INLINE LIST */
.list-inline {
  list-style: none;

  > li {
    display: inline-block;
    margin: 0 0.5em;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

/* LOZENGE LISTS */
.list-lozenges {
  margin: 0;
  padding: 0;

  .list-lozenges-item {
    display: flex;
    margin-bottom: 10px;

    .list-lozenges-item-def {
      background: $gray-lightest;
      border-radius: 0 22px 22px 0;
      color: $gray-darker;
      flex: 1 0 0;
      font: normal 1rem/22px $sans-serif;
      margin: 0;
      padding: 11px 22px 11px 16px;
      word-wrap: break-word;
    }

    .list-lozenges-item-term {
      align-items: center;
      background: $gray-lighter;
      border-radius: 22px 0 0 22px;
      color: $gray-darker;
      counter-increment: li;
      content: counter(li, upper-alpha);
      display: flex;
      flex: 0 0 0;
      font: normal 700 20px/20px $sans-serif;
      justify-content: flex-end;
      margin: 0;
      padding: 10px 14px 10px 22px;
      text-align: right;
      white-space: nowrap;

      @media screen and (min-width: $breakpoint-small) {
        flex: 0 0 15%;
      }

      @media screen and (min-width: $breakpoint-medium) {
        flex: 0 0 12%;
      }

      @media screen and (min-width: $breakpoint-large) {
        flex: 0 0 20%;
      }

      .icon {
        height: 20px;
        margin-right: 5px;
        vertical-align: -6px;
        width: 20px;
      }

      .icon-leaf {
        stroke: $gray-dark;
      }

      .icon-star {
        stroke: $gray-dark;
      }
    }

    &.list-lozenges-item-clickable {
      .list-lozenges-item-def {
        background: $gray-lightest;
      }

      .list-lozenges-item-term {
        background: $blue-light;

        .icon-leaf {
          stroke: $blue-dark;
        }

        .icon-star {
          stroke: $blue-dark;
        }
      }

      &:focus,
      &:hover {
        .list-lozenges-item-def {
          background: $blue-lightest;
          cursor: pointer;
        }

        .list-lozenges-item-term {
          background: $blue;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }
}

/*  TODO: Color variations  */
ol.list-lozenges {
  counter-reset: li;
  list-style: none;

  .list-lozenges-item {
    display: flex;
    margin-bottom: 10px;

    &:before {
      align-items: center;
      background: $blue-light;
      border-radius: 22px 0 0 22px;
      color: $gray-darker;
      counter-increment: li;
      content: counter(li, upper-alpha);
      display: flex;
      font: normal 700 20px/20px $sans-serif;
      min-width: 50px;
      padding: 12px 14px 12px 22px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .list-lozenges-item-inner {
      background: $gray-lightest;
      border-radius: 0 22px 22px 0 !important;
      flex: 1 0 0;
      font: normal 18px/22px $sans-serif;
      padding: 11px 22px 11px 16px;
      position: relative;
      word-wrap: break-word;
      text-align: left;
      &:focus,
      &:hover {
        outline: 0;
      }
    }
    &:focus {
      box-shadow: 0 0 0 0.2em $orange;
      outline: none;
      border-radius: 22px;
      outline: none;
    }
    &:focus,
    &:hover {
      cursor: pointer;
      &:before {
        background: $blue;
        color: $white;
      }

      .list-lozenges-item-inner {
        background: $blue-lightest;
      }
    }

    &[aria-checked='true'] {
      &:before {
        background-color: $blue !important;
        color: $white !important;
      }
      .list-lozenges-item-inner {
        background: $blue-lightest;
      }
    }

    &.correct,
    &.incorrect {
      cursor: default;

      &:before {
        content: '';
      }

      .icon {
        color: $white;
        height: 20px;
        left: -32px;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        width: 20px;
      }
    }

    &.selected {
      border: 5px solid $white;
      border-radius: 28px;
    }

    &.your-choice-tag {
      position: relative;
      margin-top: 20px;

      &:after {
        content: 'YOU SELECTED';
        display: block;
        background: #ffffff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -16px;
        border-radius: 4px;
        padding: 0px 10px;
        font-size: 8px;
        line-height: 2;
      }
    }

    &.correct {
      &:before {
        background-color: $green !important;
      }

      .list-lozenges-item-inner {
        background: $green-light !important;
      }
    }

    &.incorrect {
      &:before {
        background-color: $red !important;
      }

      .list-lozenges-item-inner {
        background: $red-light !important;
      }
    }

    &.concede {
      margin-top: $padding-md;
      white-space: nowrap;
    }

    &.active {
      &:before {
        color: $white;
        background: $blue !important;
      }
    }
  }
}
