@use 'sass:math';
@use './settings' as *;

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: $corner-radius;
  background: $white;
  margin-bottom: $padding-md;
  width: 100%;

  .cycle-card {
    .card-content {
      position: relative;

      @media screen and (min-width: $breakpoint-medium) {
        min-height: 244px;
      }
    }
  }

  .fullscreen-card {
    &.masquerading {
      margin-bottom: $padding-xl;
    }

    .fullscreen-card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: $padding-md 0;
      width: 100%;
      color: $gray-light;

      .fullscreen-card-sub-header {
        width: 25%;
      }
      .fullscreen-card-actions {
        width: 25%;
        display: flex;
        justify-content: flex-end;

        .action {
          padding: 5px;
          margin-left: 5px;

          &:hover,
          &:focus {
            color: $blue;
          }
        }
      }

      .fullscreen-card-title {
        color: $white;
        font: 600 48px/1 $sans-serif;
        line-height: 48px;
        text-align: center;
        width: 50%;

        @media screen and (max-width: $breakpoint-small) {
          font: 600 36px/1 $sans-serif;
        }
      }
    }

    @media screen and (max-width: $breakpoint-small) {
      .fullscreen-card-header {
        flex-direction: column;

        .fullscreen-card-title {
          order: 1;
        }
        .fullscreen-card-sub-header {
          order: 2;
        }
        .fullscreen-card-actions {
          order: 3;
        }

        .fullscreen-card-sub-header,
        .fullscreen-card-title,
        .fullscreen-card-actions {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    color: $gray-darker;
  }
  b,
  strong {
    font-weight: 600;
  }

  a {
    cursor: pointer;
    color: $blue-dark;
    &.btn {
      color: $white;
    }
    &:hover,
    &:focus {
      color: $blue;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .card-header {
    align-items: center;
    border-radius: $corner-radius $corner-radius 0 0;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 21px;
    padding: $padding-sm;
    width: 100%;
    @media only screen and (min-width: $breakpoint-medium) {
      padding: $padding-sm $padding-md;
    }
    .search-icon {
      font-size: 20px;
      line-height: 21px;
    }
    h2.title {
      font:
        14px normal,
        $sans-serif;
      color: $gray-darkish;
      line-height: 21px;
      text-transform: uppercase;
    }
    .primaryAction {
      padding: math.div($padding-sm, 3);
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: $corner-radius;
        background: $white;
        margin-bottom: $padding-md;
        width: 100%;

        /* Cards should not have H1 tags */
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 600;
          color: $gray-darker;
        }

        b,
        strong {
          font-weight: 600;
        }

        a {
          cursor: pointer;
          color: $blue-dark;

          &.btn {
            color: $white;
          }

          &:hover,
          &:focus {
            color: $blue;
          }
        }

        p {
          font-size: 16px;
          line-height: 24px;
        }

        .card-header {
          align-items: center;
          border-radius: $corner-radius $corner-radius 0 0;
          display: flex;
          font-size: 14px;
          justify-content: space-between;
          line-height: 21px;
          padding: $padding-sm;
          width: 100%;

          @media only screen and (min-width: $breakpoint-medium) {
            padding: $padding-sm $padding-md;
          }

          .search-icon {
            font-size: 20px;
            line-height: 21px;
          }

          h2.title {
            font:
              14px normal,
              $sans-serif;
            color: $gray-darkish;
            line-height: 21px;
            text-transform: uppercase;
          }

          .primaryAction {
            padding: math.div($padding-sm, 3); //increase hit zone

            &:hover,
            &:focus {
              color: $blue-dark;
            }
          }
        }

        .card-content {
          position: relative;
          display: flex;
          width: 100%;
          padding: $padding-sm;
          justify-content: flex-start;

          @media only screen and (min-width: $breakpoint-medium) {
            padding: $padding-md;
          }

          &.horizontal {
            flex-direction: row;
          }

          &.vertical {
            flex-direction: column;
          }
        }

        .card-header + .card-content {
          padding-top: 0;
        }

        .card-footer {
          display: flex;
          font-size: 14px;
          line-height: 21px;
          justify-content: flex-end;
          width: 100%;
          padding: $padding-sm;

          @media only screen and (min-width: $breakpoint-medium) {
            padding: $padding-sm $padding-md;
          }
        }

        .card-blank-state {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          padding: $padding-md 0;

          &.horizontal {
            flex-direction: row;
          }

          &.vertical {
            flex-direction: column;
          }

          &.no-padding {
            padding: 0 !important;
          }

          @media screen and (max-width: $breakpoint-medium) {
            flex-direction: column;
            justify-content: center;
          }

          h3 {
            margin-bottom: $padding-sm;
          }

          p {
            color: $gray;
            line-height: 1.3em;
          }

          img {
            margin: $padding-sm;
            max-width: 45%;
            @media screen and (max-width: $breakpoint-medium) {
              max-width: 50%;
            }
          }
        }

        &.fullscreen {
          overflow: visible;
          padding: 0;

          .card-content {
            padding: 0;
          }
        }

        &.header-bar {
          .card-header {
            background-color: $gray-lightest;
          }
          .card-content {
            padding-top: $padding-sm;
          }
        }
      }

      .cycle-card {
        .card-content {
          position: relative;

          @media screen and (min-width: $breakpoint-medium) {
            min-height: 244px;
          }
        }
      }

      .fullscreen-card {
        &.masquerading {
          margin-bottom: $padding-xl;
        }

        .fullscreen-card-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: $padding-md 0;
          width: 100%;
          color: $gray-light;

          .fullscreen-card-sub-header {
            width: 25%;
          }
          .fullscreen-card-title {
            width: 50%;
          }
          .fullscreen-card-actions {
            width: 25%;
          }

          .fullscreen-card-title {
            color: $white;
            font: 600 48px/1 $sans-serif;
            line-height: 48px;
            text-align: center;

            @media screen and (max-width: $breakpoint-small) {
              font: 600 36px/1 $sans-serif;
            }
          }
        }

        .fullscreen-card-actions {
          display: flex;
          justify-content: flex-end;

          .action {
            padding: 5px;
            margin-left: 5px;

            &:hover,
            &:focus {
              color: $blue;
            }
          }
        }

        @media screen and (max-width: $breakpoint-small) {
          .fullscreen-card-header {
            flex-direction: column;

            .fullscreen-card-title {
              order: 1;
            }
            .fullscreen-card-sub-header {
              order: 2;
            }
            .fullscreen-card-actions {
              order: 3;
            }

            .fullscreen-card-sub-header,
            .fullscreen-card-title,
            .fullscreen-card-actions {
              width: 100%;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      &:hover,
      &:focus {
        color: $blue-dark;
      }
    }
  }
  .card-content {
    position: relative;
    display: flex;
    width: 100%;
    padding: $padding-sm;
    justify-content: flex-start;
    @media only screen and (min-width: $breakpoint-medium) {
      padding: $padding-md;
    }
    &.horizontal {
      flex-direction: row;
    }
    &.vertical {
      flex-direction: column;
    }
  }
  .card-header + .card-content {
    padding-top: 0;
  }
  .card-footer {
    display: flex;
    font-size: 14px;
    line-height: 21px;
    justify-content: flex-end;
    width: 100%;
    padding: $padding-sm;
    @media only screen and (min-width: $breakpoint-medium) {
      padding: $padding-sm $padding-md;
    }
  }
  .card-blank-state {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: $padding-md 0;
    &.horizontal {
      flex-direction: row;
    }
    &.vertical {
      flex-direction: column;
    }
    &.no-padding {
      padding: 0 !important;
    }
    @media screen and (max-width: $breakpoint-medium) {
      flex-direction: column;
      justify-content: center;
    }
    h3 {
      margin-bottom: $padding-sm;
    }
    p {
      color: $gray;
      line-height: 1.3em;
    }
    img {
      margin: $padding-sm;
      max-width: 45%;
      @media screen and (max-width: $breakpoint-medium) {
        max-width: 50%;
      }
    }
  }
  &.fullscreen {
    overflow: visible;
    padding: 0;
    .card-content {
      padding: 0;
    }
  }
  &.header-bar {
    .card-header {
      background-color: $gray-lightest;
    }
    .card-content {
      padding-top: $padding-sm;
    }
  }
}
.cycle-card {
  .card-content {
    position: relative;
    @media screen and (min-width: $breakpoint-medium) {
      min-height: 244px;
    }
  }
}
.fullscreen-card {
  &.masquerading {
    margin-bottom: $padding-xl;
  }
  .fullscreen-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $padding-md 0;
    width: 100%;
    color: $gray-light;
    .fullscreen-card-sub-header {
      width: 25%;
    }
    .fullscreen-card-title {
      width: 50%;
    }
    .fullscreen-card-actions {
      width: 25%;
    }
    .fullscreen-card-title {
      color: $white;
      font: 600 48px/1 $sans-serif;
      line-height: 48px;
      text-align: center;
      @media screen and (max-width: $breakpoint-small) {
        font: 600 36px/1 $sans-serif;
      }
    }
  }
  .fullscreen-card-actions {
    display: flex;
    justify-content: flex-end;
    .action {
      padding: 5px;
      margin-left: 5px;
      &:hover,
      &:focus {
        color: $blue;
      }
    }
  }
  @media screen and (max-width: $breakpoint-small) {
    .fullscreen-card-header {
      flex-direction: column;
      .fullscreen-card-title {
        order: 1;
      }
      .fullscreen-card-sub-header {
        order: 2;
      }
      .fullscreen-card-actions {
        order: 3;
      }
      .fullscreen-card-sub-header,
      .fullscreen-card-title,
      .fullscreen-card-actions {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

/*
  .minimal-card provides a card with basic header with logo, horizontally centered card contained within $breakpoint-small (480px)
*/

.minimal-card-container {
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-medium) {
    padding-top: $padding-lg;
  }

  .minimal-card {
    @media screen and (min-width: $breakpoint-medium) {
      margin: 0 auto;
      width: $breakpoint-small;
    }

    &-title {
      padding: $padding-sm 0;
      background: $gray-darker;
      border-radius: $corner-radius $corner-radius 0 0;
      text-align: center;

      .icon-logo {
        height: 1.444rem;
        width: 4.944rem;
      }
    }

    &-content {
      background-color: $white;
      padding: $padding-sm;
      width: 100%;
      max-width: 100%;
      border-radius: 0 0 $corner-radius $corner-radius;

      @media screen and (min-width: $breakpoint-medium) {
        padding: $padding-md;
      }

      > * {
        margin-top: $padding-sm;
      }

      h1 {
        margin-bottom: $padding-md;
        text-align: center;
      }

      .return-link {
        font-size: 14px;
        line-height: 20px;
        margin-top: $padding-md;
        text-align: center;

        a {
          color: $blue-dark;

          &:hover,
          &:focus {
            color: $blue;
          }
        }
      }
    }
  }
}

.minimal-card-footer {
  color: $gray-light;
  padding: $padding-sm 0;
  text-align: center;

  a {
    &:hover {
      color: $white;
    }
  }

  .list {
    margin: 0;
  }
}

.close-memlet-btn {
  margin-bottom: $padding-xl;
}
