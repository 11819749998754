@use './settings' as *;

label {
  /*
    Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
  */
  max-width: 100%;
}

/* CONTROL CONTAINER */

.control {
  margin-bottom: $padding-sm;

  label {
    display: block;
    font-weight: 600;
  }

  input {
    /* 
      Reset unusual Firefox-on-Android default style.
      See https://github.com/necolas/normalize.css/issues/214
    */
    background-image: none;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;
    color: $input-color;
    display: block;
    font-size: $base-font-size;
    line-height: $line-height-base;
    padding: $padding-sm * 0.5;
    width: 100%;

    &[disabled] {
      color: $gray;
      cursor: not-allowed;
    }
  }

  .helper-text {
    color: $gray;
    font-size: 85%;
    line-height: 1.5;
  }

  .pseudo-label {
    font-weight: 600;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: $padding-sm * 0.5;
  }
}

.control-feedback {
  color: currentColor;
  font-size: 80%;
  font-style: italic;
  padding-left: 1.5rem;
  position: relative;

  a {
    color: currentColor;
    text-decoration: underline;

    &:focus {
      box-shadow: 0 0 0 0.2em $orange;
      outline: none;
    }

    &:focus,
    &:hover {
      color: currentColor;
      text-decoration: none;
    }
  }

  &.hidden {
    margin: 0 !important;
    visibility: hidden;
  }

  &:before {
    background-position: 0.25rem center;
    background-repeat: no-repeat;
    background-size: 55% 55%;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 1rem;
    left: 0.05rem;
    line-height: 1rem;
    position: absolute;
    top: 0.2em;
    width: 1rem;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  &.busy {
    color: $gray-dark;

    &:before {
      animation: spin 1s linear infinite;
      background-color: transparent;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNSAxNyI+CiAgPHBhdGggZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTQuMTIgMTIuMzkzbC42NzkuNDRhOC41IDguNSAwIDAxLTExLjQ2OCAyLjY4bC0uOTcgMS4xOTktMS44NS00LjA3MiA0LjM2Ny45NjMtMS4wMzEgMS4yNzRhNy42OTEgNy42OTEgMCAwMDEwLjE1LTIuMzAybC4xMjMtLjE4MnpNMTIuNjgyLjQzbDEuODUgNC4wNzEtNC4zNjctLjk2MyAxLjAzMS0xLjI3NGE3LjY5MSA3LjY5MSAwIDAwLTEwLjE1IDIuMzAzbC0uMTIyLjE4Mi0uNjgtLjQ0MWE4LjUgOC41IDAgMDExMS40NjgtMi42OGwuOTctMS4xOTh6Ii8+Cjwvc3ZnPgo=);
      background-position: center center;
      background-size: 70% 70%;
    }
  }

  &.error {
    color: $red-dark;

    &:before {
      background-color: $red-dark;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3IDIxIj4KICA8cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0zLjUwMyAxNS4yMDFjMS42NjkgMCAzLjAyOSAxLjI1MSAzLjAyOSAyLjc2MyAwIDEuNTA4LTEuMzU3IDIuNzM2LTMuMDMgMi43MzYtMS42MjQgMC0yLjk3LTEuMjM4LTIuOTctMi43MzYgMC0xLjUwMSAxLjM0OS0yLjc2MyAyLjk3LTIuNzYzek01LjEzNy43Yy41ODIgMCAxLjA4NS40MiAxLjE1NC45MzlsLjAwOC4xMi0uMDA2IDMuMDU3LTEuMTM3IDcuNDdjLS4wNjMuNDYtLjUxOC44NDctMS4wMjkuOTAzTDQgMTMuMTk3aC0uOTM0Yy0uNTE3IDAtMS4wMDctLjM1Mi0xLjEzMy0uNzkybC0uMDIzLS4xMTItMS4xNDQtNy41VjEuNzZjMC0uNTMxLjQ2MS0uOTkgMS4wMy0xLjA1M0wxLjkyNy43aDMuMjF6Ii8+Cjwvc3ZnPgo=);
    }
  }

  &.info {
    color: $blue-dark;

    &:before {
      background-color: $blue-dark;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDIxIj4KICA8cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0zLjc5IDUuMTg3Yy42MTQgMCAxLjE0LS4yMTYgMS41NzctLjY0OS40MzctLjQzMi42NTYtLjk1NS42NTYtMS41NyAwLS42MTUtLjIxNi0xLjE0LS42NDktMS41NzhBMi4xNDQgMi4xNDQgMCAwMDMuNzkuNzM0Yy0uNjE0IDAtMS4xMzguMjE5LTEuNTcuNjU2YTIuMTYzIDIuMTYzIDAgMDAtLjY0OSAxLjU3OGMwIC42MTUuMjE2IDEuMTM4LjY0OSAxLjU3YTIuMTQgMi4xNCAwIDAwMS41Ny42NDl6bTMuNjQ1IDE1LjU0N3YtLjUxOGMtLjY0NC0uMDM5LTEuMDc2LS4xOS0xLjI5Ny0uNDU0LS4yMi0uMjY0LS4zMzEtLjg3Mi0uMzMxLTEuODIzVjcuMjYySC4xNTh2LjUzM2MuNjM0LjAzOCAxLjA2Mi4xOSAxLjI4My40NTQuMjIuMjY0LjMzMS44NzIuMzMxIDEuODIzdjcuODY3YzAgLjk1MS0uMDk2IDEuNTM3LS4yODggMS43NTgtLjI4OC4zMjctLjczLjUtMS4zMjYuNTE5di41MThoNy4yNzd6Ii8+Cjwvc3ZnPgo=);
    }
  }

  &.success {
    color: $green-dark;

    &:before {
      background-color: $green-dark;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
    }
  }
}

/* 
  CUSTOM CONTROLS - Used to combine uikit controls. For example, adding a checkbox
                    below a text input, or the help links on the login screens.
*/
.centered-controls {
  display: flex;
  flex-direction: column;
  align-items: center;

  .remember-me-control {
    margin: $padding-md 0px 0px;
  }
}

.help-controls {
  text-align: center;

  .help-link {
    display: inline-block;
    margin: 0px $padding-xs;

    a {
      color: $blue-dark;
      &:focus,
      &:hover {
        outline: none;
        color: $blue;
        text-decoration: underline;
      }

      &:focus {
        box-shadow: 0 0 0 0.2em $orange;
      }
    }
  }

  &.forgot-password-controls {
    margin-top: $padding-md;
  }
}

.control-with-help {
  margin-bottom: $padding-sm;
  position: relative;

  .control {
    margin: 0;

    @media screen and (min-width: $breakpoint-medium) {
      padding-bottom: 1.5em;
    }

    &.invalid {
      padding-bottom: 0;
    }
  }

  .show-password {
    display: flex;
    justify-content: flex-end;
  }
}

.control-with-checkbox {
  .control {
    padding: 0;

    &.invalid ~ .help-control {
      bottom: 1.75em;
    }
  }

  .checkbox {
    label {
      padding-left: 1em;
      margin: 0;
    }

    .control-indicator {
      height: 0.8em;
      width: 0.8em;
      line-height: 0.8em;
      top: 0.4em;
    }

    .control-label {
      font-size: 80%;
    }
  }
}

/* CHECKBOXES & RADIO BUTTONS */

.checkbox,
.radiogroup {
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 0.1rem;
    top: 0.2rem;

    &:checked ~ .control-indicator {
      background-color: currentColor;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:focus ~ .control-indicator {
      box-shadow: 0 0 0 0.2em $orange;
      outline: none;
    }

    &:focus ~ .control-indicator,
    &:hover ~ .control-indicator {
      border-color: $blue;
    }

    &:focus ~ .control-label,
    &:hover ~ .control-label {
      color: $blue;
    }

    &:checked:focus ~ .control-indicator,
    &:checked:hover ~ .control-indicator {
      background-color: $blue;
    }

    &.invalid ~ .control-indicator,
    &.invalid:checked ~ .control-indicator {
      border-color: $red-dark;
    }

    &.invalid:checked ~ .control-indicator {
      background-color: $red-dark;
    }

    &.invalid ~ .control-label {
      color: $red-dark;
    }

    &[disabled] ~ .control-indicator {
      border-color: $gray;
    }

    &[disabled]:checked ~ .control-indicator {
      background-color: $gray !important;
    }

    &[disabled]:checked:focus ~ .control-indicator,
    &[disabled]:checked:hover ~ .control-indicator {
      background-color: $gray !important;
    }

    &[disabled] ~ .control-label {
      color: $gray;
    }
  }

  label {
    color: currentColor;
    cursor: pointer;
    display: inline-block;
    padding-left: 1.5rem;

    .control-indicator {
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 50% 50%;
      border: 1px solid currentColor;
      display: block;
      height: 1rem;
      left: 0;
      line-height: 1rem;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: 0.25rem;
      user-select: none;
      width: 1rem;
    }
  }

  .helper-text {
    margin-left: 1.5rem;
  }
}

.checkbox {
  position: relative;

  input {
    &:checked ~ .control-indicator {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
    }
  }

  label {
    margin-bottom: 0.5rem;

    .control-indicator {
      border-radius: 0.25rem;
    }
  }

  &.disabled {
    label {
      cursor: not-allowed;
    }
  }

  .control-feedback {
    margin-bottom: 0.25rem;
    margin-top: -0.25rem;
  }

  .control-label {
    font-weight: 600;
    font-size: 1rem;
  }
}

// TODO: Does not allow user to click.
.ikt-checkbox {
  background-color: $tan;
  border: 1px solid $tan-dark;
  border-radius: $corner-radius-small;
  display: inline-block;
  margin-bottom: 0;
  margin-left: 0.5rem;
  padding: 0 0.25rem;

  label {
    margin-bottom: 0;
    padding-left: 1rem;

    input {
      left: 0;

      &:focus ~ .control-indicator,
      &:hover ~ .control-indicator {
        border-color: $gray-darkest;
      }

      &:checked ~ .control-indicator {
        color: $tan-dark;
      }

      &:checked:focus ~ .control-indicator,
      &:checked:hover ~ .control-indicator {
        background-color: $gray-darkest;
      }

      &:checked:focus ~ .control-label,
      &:checked:hover ~ .control-label {
        color: $gray-darkest;
      }
    }

    .control-indicator {
      height: 0.8rem;
      left: 0.25rem;
      top: 0.4rem;
      width: 0.8rem;
    }

    .control-label {
      color: $gray-darker;
      font-size: 80%;
    }
  }
}

.radiogroup {
  border: 0;
  display: block;
  margin: 0 0 1.5rem;
  min-width: 0;
  padding: 0.01em 0 0 0;

  input {
    &:checked ~ .control-indicator {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
    }

    &[disabled] ~ .control-label {
      color: $gray;
      cursor: not-allowed;
    }
  }

  label {
    .control-indicator {
      border-radius: 50%;
    }
  }

  legend {
    display: table;
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 0;

    &.invalid {
      color: $red-dark;
    }
  }

  .control-feedback {
    margin-bottom: 1rem;
    margin-top: -0.5rem;
  }

  .radiogroup-item {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &.disabled {
      label {
        cursor: not-allowed;
      }
    }
  }

  &.disabled {
    legend {
      color: $gray;
    }
  }
}

/* DATEPICKER INPUT */

.datepicker {
  border: 0;
  padding: 0;

  legend {
    color: currentColor;
    display: table;
    font-weight: 600;
    margin-bottom: 7.5px;
    padding: 0;

    &.invalid {
      color: $red;
    }
  }

  .container {
    margin: 0;
    padding: 0;

    .select-menu {
      margin-bottom: 0;

      // Adding adequate space in mobile/smaller screen
      @media screen and (max-width: 768px) {
        margin: 0 0.5em 0.5em 0 !important;
      }
    }

    * + * {
      margin-left: 0.5em;
    }
  }

  // TODO: Fix this. One or the other might not be there. Top margin only.
  .control-feedback,
  .helper-text {
    margin-top: 0.5em;
  }

  .helper-text {
    margin-left: 0;
  }
}

/* SELECT MENUS */
/* - VERY helpful in determining filter values: https://codepen.io/sosuke/pen/Pjoqqp */

.select {
  label {
    display: block;
    font-weight: 600;
  }

  .control-feedback {
    margin-bottom: 0.25rem;
  }

  .select-menu {
    display: inline-block;
    margin-bottom: 0.5rem;
    position: relative;

    &:after {
      // Caret icon
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAxNSI+CiAgPHBhdGggZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTUgMTVMMCAwaDMweiIvPgo8L3N2Zz4K);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 75% 75%;
      content: '';
      display: inline-block;
      filter: brightness(0);
      height: 2.5rem;
      margin-top: -0.15rem;
      pointer-events: none;
      position: absolute;
      right: 1rem;
      top: 0.2rem;
      width: 1rem;
    }

    &:hover {
      &:after {
        filter: invert(59%) sepia(37%) saturate(6074%) hue-rotate(178deg)
          brightness(101%) contrast(101%);
      }
    }

    select {
      appearance: none;
      background: none;
      border: 1px solid $gray-lightish;
      border-radius: 0.25rem;
      color: $gray-darker;
      cursor: pointer;
      font-size: 1rem;
      line-height: 1.5;
      margin: 0;
      outline: 0;
      padding: 0.4rem 2.75rem 0.5rem 1rem;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        color: $blue;
      }

      &:focus {
        box-shadow: 0 0 0 0.2em $orange;
        outline: none;
      }

      /* Undo the Firefox inner focus ring */
      &:focus:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $black;
      }

      &.invalid,
      &.invalid:hover {
        border-color: $red-dark;
        color: $red-dark;
      }

      /* Hide the arrow in IE10 and up */
      &::-ms-expand {
        display: none;
      }

      &[disabled] {
        color: $gray;
      }
    }

    /* Media query to target Firefox only */
    @-moz-document url-prefix() {
      /* <option> elements inherit styles from <select>, so reset them. */
      option {
        background-color: $white;
        color: $black;
      }
    }
  }

  &.disabled {
    label {
      color: $gray;
    }

    .select-menu {
      &:after {
        filter: invert(45%) sepia(0%) saturate(447%) hue-rotate(150deg)
          brightness(102%) contrast(84%);
      }

      select {
        background-color: $gray-light;
        border-color: $gray-light;
        cursor: not-allowed;
      }
    }
  }

  &.full-width {
    .select-menu {
      width: 100%;
    }
  }

  &.inline {
    label {
      display: inline-block;
      margin-right: 1rem;
    }
  }

  &.invalid {
    label {
      color: $red-dark;
    }

    .select-menu {
      &:after {
        filter: invert(15%) sepia(93%) saturate(5873%) hue-rotate(358deg)
          brightness(105%) contrast(116%);
      }
    }
  }

  .helper-text {
    margin-left: 0;
  }
}

/* TEXT INPUTS */

.text {
  input,
  textarea {
    border: 1px solid $gray-lightish;
    border-radius: $corner-radius;
    color: currentColor;
    display: block;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    padding: 0.5rem;

    &:focus {
      box-shadow: 0 0 0 0.2em $orange;
      outline: none;
    }

    &:focus,
    &:hover {
      border-color: $blue;
    }

    &[disabled] {
      border-color: $gray;
      color: $gray;
      cursor: not-allowed;
    }
  }

  textarea {
    width: 100%;
  }

  .control-feedback {
    margin-bottom: 0.25rem;
  }

  .helper-text {
    margin-left: $corner-radius;
  }

  &.inline {
    input,
    label,
    .control-label {
      display: inline-block;
    }

    input {
      width: auto;
    }

    label {
      margin-bottom: 0rem;
      margin-right: 0.5rem;
    }
  }

  &.invalid {
    input,
    textarea {
      border-color: $red-dark;
    }

    label {
      color: $red-dark;
    }
  }
}

/*
  TODO: Replace everything below with the standardized "control" component styles.
        Can be overridden with scoped styles.
*/

label {
  display: inline-block;
  max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
  margin-bottom: $padding-sm * 0.5;
}

// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-group {
  margin-bottom: $padding-sm;

  label {
    font-weight: 600;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: $padding-sm * 0.5;
  font-size: $base-font-size;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }

  &::placeholder {
    color: $gray-light;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    border-color: $gray-lighter;
    color: $gray-lightish;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }

  &:focus {
    border-color: $blue;
  }

  &.error {
    border-color: $red-dark;
  }
}

// Reset height for `textarea`s
textarea.form-control {
  height: auto;

  &.error {
    border: 1px solid red;
  }
}
