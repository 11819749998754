@use './settings' as *;

/* Buttons */
.btn {
  background: $gray-lightest;
  border: none;
  border-radius: 20px;
  color: $gray-darker;
  display: inline-block;
  font: normal 700 15px/15px $sans-serif;
  padding: 13px $padding-md 12px;
  white-space: nowrap;

  @media print, screen and (min-width: $breakpoint-large) {
    border-radius: $corner-radius-large;
    font: normal 700 1rem/1rem $sans-serif;
    padding: 16px $padding-md 14px;
  }

  .icon {
    fill: currentColor;
  }

  &:focus {
    box-shadow: 0 0 0 0.2em $orange;
    outline: none;
  }

  &:focus,
  &:hover,
  &.active {
    background: $gray-lighter;
    cursor: pointer;
  }

  &[aria-disabled=true],
  &[disabled],
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:focus,
    &:hover {
      background: $gray-lighter;
    }
  }
}

/*  Button colors  */

.btn-black {
  background: $gray-darker;
  color: $gray-light;

  &:focus,
  &:hover,
  &.active {
    background: $gray-darkest;
    color: $gray-light;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $gray-darker;
  }
}

.btn-blue {
  background: $blue-dark;
  color: $white;

  &:focus,
  &:hover,
  &.active {
    background: $blue;
    color: $white;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $blue-dark;
  }
}

.btn-blue-light {
  background: $blue-light;
  color: $gray-darker;

  &:focus,
  &:hover,
  &.active {
    background: $blue;
    color: $white;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $blue-light;
    color: $gray-darker;
  }
}

.btn-blue-lightest {
  background: $blue-lightest;
  color: $gray-darker;

  &:focus,
  &:hover,
  &.active {
    background: $blue;
    color: $white;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $blue-lightest;
    color: $gray-darker;
  }
}

.btn-gray {
  background: $gray-lightest;
  color: $gray;

  &:focus,
  &:hover,
  &.active {
    background: $gray-lighter;
    color: $gray-darker;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $gray-lightest;
    color: $gray;
  }
}

.btn-gray-dark {
  background: $gray-light;
  color: $white;

  &:focus,
  &:hover,
  &.active {
    background: $blue;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $gray-light;
  }
}

.btn-green {
  background: $green;
  color: $white;
  margin: 0 $padding-sm;

  &:focus,
  &:hover,
  &.active {
    background: $green-dark;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $green-dark;
  }
}

.btn-inline {
  font-family: $sans-serif;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  padding: 2px 15px 3px;

  &:focus,
  &:hover,
  &.active {
    font-weight: 700;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    font-family: $sans-serif;
  }
}

.btn-legal-pad {
  background: $tan;
  color: $gray-darker;

  &:focus,
  &:hover,
  &.active {
    background: $tan-dark;
    color: $white;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $tan;
    color: $gray-darker;
  }
}

.btn-red {
  background: $red;
  color: $white;
  margin: 0 $padding-sm;

  &:focus,
  &:hover,
  &.active {
    background: $red-dark;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $red-dark;
  }
}

.btn-white {
  background: $white;
  color: $gray-darker;

  &:focus,
  &:hover,
  &.active {
    background: $blue;
    color: $white;
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    background: $white;
    color: $gray-darker;
  }
}

/*  Button sizes  */
.btn-full {
  font: normal 700 17px/17px $sans-serif;
  padding: 20px;
  width: 100%;
}

.btn-full-mobile-only {
  @media print, screen and (max-width: $breakpoint-small) {
    width: 100%;
  }
}

.btn-large {
  border-radius: 30px;
  font-size: 32px;
  line-height: 32px;
  padding: 16px 30px;
}

.btn-lozenge {
  &:focus,
  &:hover {
    background: $blue-lightest !important;
  }

  &[href='#'] {
    &:hover {
      cursor: default !important;
    }
  }
}

.btn-lozenge-icon {
  padding-left: 55px;

  /* TODO: Padding needs to change for large screens */
  .icon-container,
  .icon-container-roots {
    background: $blue-light;
    border-radius: 50% 0 0 50%;
    height: 100%;
    left: 0;
    padding: 11px 8px 9px 15px;
    position: absolute;
    top: 0;

    @media print, screen and (min-width: $breakpoint-large) {
      padding: 13px 8px 13px 15px;
    }
  }

  .icon-leaf {
    fill: $green-leaf;
    height: 22px;
    stroke: $blue-dark;
    width: 22px;
  }

  &:focus,
  &:hover {
    .icon-container {
      background: $blue;
    }
  }
}

.btn-small {
  font-size: 17px !important;
  line-height: 17px !important;
  padding: 10px 20px;
}

.btn-xs-small {
  font-size: 12px !important;
  line-height: 12px !important;
  padding: 6px 12px 7px;
}
.btn-block {
  width: 100%;
}

/* Button styles */
.btn-audio {
  & + * {
    padding-left: 6px;
  }

  &:hover,
  &:focus {
    .icon {
      fill: $blue;
    }
  }

  &[aria-disabled=true],
  &[aria-disabled=true]:focus,
  &[aria-disabled=true]:focus,
  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    .icon {
      fill: currentColor;
    }
  }

  &-gray {
    .icon {
      fill: $gray-lightish;
    }
  }

  &-large {
    .icon {
      height: 3rem;
      width: 3rem;
    }
  }
}

.btn-call-to-action {
  font: normal 700 20px/20px $sans-serif;
  padding: 12px $padding-md 11px;

  .icon {
    height: 1rem;
    vertical-align: -2px;
    width: 1rem;
  }

  @media print, screen and (min-width: $breakpoint-large) {
    font: normal 700 22px/22px $sans-serif;
    padding: 13px $padding-md 12px;
  }
}

.btn-icon-circle {
  border-radius: 50%;
  padding: $padding-sm;

  &.small {
    padding: $padding-sm*0.25;
  }

  .icon {
    height: 20px;
    vertical-align: -5px;
    width: 20px;
  }

  @media print, screen and (min-width: $breakpoint-large) {
    .icon {
      height: 23px;
      width: 23px;
    }
  }
}

.btn-icon-left {
  .icon {
    margin-right: 0.5em;
  }
}

.btn-icon-right {
  .icon {
    margin-left: 0.5em;
  }
}

.btn-link {
  background: none;
  border-radius: 0;
  color: $blue-dark;
  font: inherit;
  padding: 0;

  &:hover,
  &:focus {
    background: none;
    color: $blue;
  }
}

.btn-square {
  border-radius: 0;
}