@use './settings' as *;
/* Callout Component */

.callout {
  background: $gray-lightest;
  border-radius: $corner-radius-medium;
  font: normal 17px/24px $sans-serif;
  padding: 1rem 20px;

  @media screen and (min-width: $breakpoint-small) {
    font: normal 19px/27px $sans-serif;
    padding: 1rem 30px;
  }

  a,
  button {
    .icon {
      fill: $gray-lightish;
    }

    &:focus,
    &:hover {
      .icon {
        fill: $gray-darkest;
      }
    }
  }
}

.callout-blue-light {
  background: $blue-lightest;
}

.callout + .callout {
  margin-top: 15px;
}
