@use './settings' as *;

/*  Notepad Component  */
.notepad {
  background-color: $tan;
  border-radius: $corner-radius;
  border-top: $padding-md solid $tan-dark;
  position: relative;
  width: 100%;

  @media screen and (min-width: $breakpoint-small) {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAABCAYAAADjAO9DAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTNEMzlERjkyQjE2MTFFNzk2M0FFNkIzMTE4QTMyNjYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTNEMzlERkEyQjE2MTFFNzk2M0FFNkIzMTE4QTMyNjYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxM0QzOURGNzJCMTYxMUU3OTYzQUU2QjMxMThBMzI2NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxM0QzOURGODJCMTYxMUU3OTYzQUU2QjMxMThBMzI2NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnAbtT8AAAAZSURBVHjaYpo5NeM/EDP8//8fA4PkAAIMAF/EFbSbtAegAAAAAElFTkSuQmCC');
    background-position: $padding-sm top;
    background-repeat: repeat-y;
  }

  textarea {
    background: none;
    border: 0;
    color: $gray-darker;
    max-height: 50vh;
    resize: none;
    width: 100%;
    padding: $padding-sm $padding-md;

    &::placeholder {
      text-align: left;
    }
  }

  .notepad-feedback {
    color: $tan;
    font: normal 700 14px/14px $sans-serif;
    position: absolute;
    right: $padding-md;
    top: -23px;
  }
}
