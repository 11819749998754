@use './settings' as *;

button.btn-datepicker {
  border: 1px solid $input-border;
  border-radius: $input-border-radius;
  color: $input-color;
  font: normal 14px/1rem $sans-serif;
  padding: $padding-xs;
  text-transform: uppercase;

  &:focus,
  &:hover {
    border-color: $blue;
  }

  &.error {
    border-color: $red;
  }
}

.rdt {
  display: inline-block;
}

.rdtOpen {
  .rdtPicker {
    display: block;
    position: absolute;
    width: 300px;
    z-index: 9999;

    @media only screen and (max-width: $breakpoint-small) {
      position: fixed;
      width: 100%;
      max-width: 600px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.rdtPicker {
  background: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  color: $gray-darker;
  display: none;

  table {
    border-spacing: 0;
    border: 0;
    border: none;

    thead {
      background-color: $gray-lightest;
    }
  }

  .dow {
    font-weight: 600;
    font-size: 12px;
    font-weight: normal !important;
    text-align: center;
    text-transform: uppercase;
  }

  .rdtPrev,
  .rdtNext,
  .rdtSwitch,
  .rdtDay,
  .rdtMonth,
  .rdtYear {
    cursor: pointer;
    font: normal 600 16px $sans-serif !important;
    text-align: center;
  }

  .rdtDays,
  .rdtYears {
    display: flex;
    flex-direction: row;
    height: 300px;
  }

  .rdtMonths {
    display: flex;
    flex-direction: column;
    height: 300px;
  }

  .rdtMonths table,
  .rdtYears table {
    width: 100%;
    height: 100%;
  }

  .rdtDays table {
    width: 80%;
    height: 100%;
  }

  .rdtDays.rdtNoTime table {
    width: 100%;
    height: 100%;
  }

  .rdtYears table thead tr,
  .rdtMonths table thead tr,
  .rdtDays table thead tr {
    height: 30px;
    line-height: 30px;
  }

  .rdtYears table thead th,
  .rdtMonths table thead th,
  .rdtDays table thead th {
    vertical-align: middle;
  }

  .rdtNext,
  .rdtPrev,
  .rdtSwitch {
    font-weight: 700;

    &:hover,
    &:focus {
      color: $blue;
    }
  }

  .rdtDay {
    &:hover {
      background-color: $blue-light;
    }

    &.rdtDisabled {
      color: $gray-light;

      &:hover {
        background-color: $blue-lightest;
      }
    }
  }

  .rdtActive {
    background-color: $blue;
    color: $white;
    &:hover,
    &:focus {
      background-color: $blue-dark;
    }
  }

  .rdtToday {
    background-color: $tan;
    color: $gray-darker;

    &:before {
      content: none;
    }
  }

  .time-selector-container {
    background: $white;
    border-left: 1px solid $gray-lighter;
    overflow: scroll;
    width: 20%;

    .time-selector-times {
      list-style-type: none;
      margin: 0;
      overflow: scroll;
      padding: 0;
      width: 100%;

      .time-selector-time {
        cursor: pointer;
        font: normal 12px/1em $sans-serif;
        padding: 8px 0;
        text-align: center;

        &:hover {
          background: $blue;
          color: $white;
        }
      }
    }
  }
}
