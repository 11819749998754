@use '../settings' as *;

/* Color Overrides */
$earth-brown-dark: #40382F;
$earth-brown-darker: #2B2620;
$earth-gray: #939598;
$earth-orange-lightest: #FCEDDC;
$earth-orange-light: #F8CFA2;
$earth-orange: #ED8817;


/* Theme overrides */
.earth {
  background: $earth-brown-dark;

  /* BEGIN: Buttons */
  .btn-black {
    background: $earth-brown-darker;
  }
  /* END: Buttons */

  /* BEGIN: Lozenge List */
  ol.list-lozenges {
    .list-lozenges-item {
      &:before {
        background: $earth-orange-light;
      }

      .list-lozenges-item-inner {
        background: $white;
      }

      &:focus,
      &:hover {
        &:before {
          background: $earth-orange;
        }

        .list-lozenges-item-inner {
          background: $earth-orange-lightest;
        }
      }
    }
  }
  /* END: Lozenge List */

  /* BEGIN: Topbar */
  .topbar {
    background: $earth-brown-darker;
    .list-inline {
      > li {
        > a, button {
          color: $gray-lightish;
        }
      }
    }
  }
  /* END: Topbar */
}
