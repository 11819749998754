@use './settings' as *;

.extension-detection-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .extension-detection-modal {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    max-width: 900px;
    top: 30%;
    text-align: left !important;

    &:focus {
      box-shadow: 0 0 0 0.2em $orange;
      outline: none;
    }

    .extension-detection-title {
      font:
        18px normal,
        'Nunito',
        sans-serif;
      font-weight: 600;
      color: $gray-darkish;
      line-height: 21px;
      text-transform: uppercase;
    }

    .extension-detection-description {
      font:
        16px normal,
        'Nunito',
        sans-serif;
      color: $gray-darkish;
      line-height: 21px;
      margin: 24px 0px;
    }

    .extensions {
      border-top: 1px solid $gray-lightest;
      border-bottom: 1px solid $gray-lightest;
      padding-top: $padding-sm;
      .extension-item {
        display: flex;
        align-items: center;
        margin-bottom: $padding-sm;
        .extension-icon {
          width: 30px;
          height: 30px;
          margin-right: 8px;
        }
        & > a {
          color: $gray-darkish;
          font:
            24px normal,
            'Nunito',
            sans-serif;
          font-weight: 600;
          line-height: 1;
        }

        & > a:focus,
        & > a:hover {
          color: $blue-pictone;
        }
      }
    }

    .extension-modal-footer {
      display: flex;
      justify-content: flex-end;

      .acknowledge-btn {
        margin-left: $padding-sm;
      }
    }
  }

  @media (max-width: 768px) {
    .extension-detection-modal {
      width: 90%;
      margin: 20px auto;
    }
  }
}
