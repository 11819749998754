@use './settings' as *;

.notification {
  color: $white;
  border-radius: 50%;
  display: inline-block;
  font-size: 13px !important;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 20px;

  &.warning {
    background-color: $orange;
    color: $gray-darker;
  }
}
