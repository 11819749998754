/*
  Flex Grid
  Slimmed down flex grid extracted from Foundation 6 Sites
  http://foundation.zurb.com/sites/docs/flex-grid.html
*/
@use './settings' as *;

.container {
  margin: 0 auto;
  max-width: $breakpoint-large;
  padding-left: $padding-sm; // 15px
  padding-right: $padding-sm; // 15px
  position: relative;
  width: 100%;
}

.row {
  margin: 0 auto;
  max-width: $breakpoint-large;
  display: flex;
  flex-wrap: wrap;

  & + .row {
    margin-top: $padding-md;
  }

  .row {
    margin-right: -$padding-sm; // 15px
    margin-left: -$padding-sm; // 15px

    &.collapse {
      margin-right: 0;
      margin-left: 0;
      //padding-bottom: 30px;
    }

    .column,
    .columns {
      padding-right: $padding-sm; // 15px
      padding-left: $padding-sm; // 15px
    }
  }

  &.collapse {
    > .column,
    > .columns {
      padding-right: 0;
      padding-left: 0;

      > .row {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &.expanded {
    max-width: none;
  }

  &:not(.expanded) {
    .row {
      max-width: none;
    }
  }

  &.medium-unstack {
    > .column,
    > .columns {
      flex: 0 0 100%;
    }
  }

  &.large-unstack {
    > .column,
    > .columns {
      flex: 0 0 100%;
    }
  }
}

.column,
.columns {
  flex: 1 1 0px;
  padding-right: $padding-sm; // 15px
  padding-left: $padding-sm; // 15px
  min-width: initial;
}

.extra-small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.extra-small-offset-0 {
  margin-left: 0%;
}

.extra-small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.extra-small-offset-1 {
  margin-left: 8.33333%;
}

.extra-small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.extra-small-offset-2 {
  margin-left: 16.66667%;
}

.extra-small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.extra-small-offset-3 {
  margin-left: 25%;
}

.extra-small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.extra-small-offset-4 {
  margin-left: 33.33333%;
}

.extra-small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.extra-small-offset-5 {
  margin-left: 41.66667%;
}

.extra-small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.extra-small-offset-6 {
  margin-left: 50%;
}

.extra-small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.extra-small-offset-7 {
  margin-left: 58.33333%;
}

.extra-small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.extra-small-offset-8 {
  margin-left: 66.66667%;
}

.extra-small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.extra-small-offset-9 {
  margin-left: 75%;
}

.extra-small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.extra-small-offset-10 {
  margin-left: 83.33333%;
}

.extra-small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.extra-small-offset-11 {
  margin-left: 91.66667%;
}

.extra-small-order-1 {
  order: 1;
}

.extra-small-order-2 {
  order: 2;
}

.extra-small-order-3 {
  order: 3;
}

.extra-small-order-4 {
  order: 4;
}

.extra-small-order-5 {
  order: 5;
}

.extra-small-order-6 {
  order: 6;
}

.extra-small-up-1 {
  flex-wrap: wrap;
}

.extra-small-up-1 > .column,
.extra-small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.extra-small-up-2 {
  flex-wrap: wrap;
}

.extra-small-up-2 > .column,
.extra-small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.extra-small-up-3 {
  flex-wrap: wrap;
}

.extra-small-up-3 > .column,
.extra-small-up-3 > .columns {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.extra-small-up-4 {
  flex-wrap: wrap;
}

.extra-small-up-4 > .column,
.extra-small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.extra-small-up-5 {
  flex-wrap: wrap;
}

.extra-small-up-5 > .column,
.extra-small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.extra-small-up-6 {
  flex-wrap: wrap;
}

.extra-small-up-6 > .column,
.extra-small-up-6 > .columns {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.extra-small-up-7 {
  flex-wrap: wrap;
}

.extra-small-up-7 > .column,
.extra-small-up-7 > .columns {
  flex: 0 0 14.28571%;
  max-width: 14.28571%;
}

.extra-small-up-8 {
  flex-wrap: wrap;
}
.extra-small-up-8 > .column,
.extra-small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.extra-small-collapse > .column,
.extra-small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.extra-smallsmall-uncollapse > .column,
.extra-small-uncollapse > .columns {
  padding-right: $padding-sm; // 15px
  padding-left: $padding-sm; // 15px
}

/* Wider than 480px */
@media print, screen and (min-width: $breakpoint-small) {
  .small-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .small-offset-0 {
    margin-left: 0%;
  }

  .small-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .small-offset-1 {
    margin-left: 8.33333%;
  }

  .small-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .small-offset-2 {
    margin-left: 16.66667%;
  }

  .small-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .small-offset-3 {
    margin-left: 25%;
  }

  .small-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .small-offset-4 {
    margin-left: 33.33333%;
  }

  .small-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .small-offset-5 {
    margin-left: 41.66667%;
  }

  .small-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .small-offset-6 {
    margin-left: 50%;
  }

  .small-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .small-offset-7 {
    margin-left: 58.33333%;
  }

  .small-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .small-offset-8 {
    margin-left: 66.66667%;
  }

  .small-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .small-offset-9 {
    margin-left: 75%;
  }

  .small-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .small-offset-10 {
    margin-left: 83.33333%;
  }

  .small-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .small-offset-11 {
    margin-left: 91.66667%;
  }

  .small-order-1 {
    order: 1;
  }

  .small-order-2 {
    order: 2;
  }

  .small-order-3 {
    order: 3;
  }

  .small-order-4 {
    order: 4;
  }

  .small-order-5 {
    order: 5;
  }

  .small-order-6 {
    order: 6;
  }

  .small-up-1 {
    flex-wrap: wrap;
  }

  .small-up-1 > .column,
  .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .small-up-2 {
    flex-wrap: wrap;
  }

  .small-up-2 > .column,
  .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .small-up-3 {
    flex-wrap: wrap;
  }

  .small-up-3 > .column,
  .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .small-up-4 {
    flex-wrap: wrap;
  }

  .small-up-4 > .column,
  .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .small-up-5 {
    flex-wrap: wrap;
  }

  .small-up-5 > .column,
  .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .small-up-6 {
    flex-wrap: wrap;
  }

  .small-up-6 > .column,
  .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .small-up-7 {
    flex-wrap: wrap;
  }

  .small-up-7 > .column,
  .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }

  .small-up-8 {
    flex-wrap: wrap;
  }
  .small-up-8 > .column,
  .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .small-collapse > .column,
  .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }

  .small-uncollapse > .column,
  .small-uncollapse > .columns {
    padding-right: $padding-sm; // 15px
    padding-left: $padding-sm; // 15px
  }
}

/* Wider than 768px */
@media print, screen and (min-width: $breakpoint-medium) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .medium-offset-1 {
    margin-left: 8.33333%;
  }

  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-offset-2 {
    margin-left: 16.66667%;
  }

  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .medium-offset-4 {
    margin-left: 33.33333%;
  }

  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-offset-5 {
    margin-left: 41.66667%;
  }

  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .medium-offset-7 {
    margin-left: 58.33333%;
  }

  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .medium-offset-8 {
    margin-left: 66.66667%;
  }

  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .medium-offset-10 {
    margin-left: 83.33333%;
  }

  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-offset-11 {
    margin-left: 91.66667%;
  }

  .medium-order-1 {
    order: 1;
  }

  .medium-order-2 {
    order: 2;
  }

  .medium-order-3 {
    order: 3;
  }

  .medium-order-4 {
    order: 4;
  }

  .medium-order-5 {
    order: 5;
  }

  .medium-order-6 {
    order: 6;
  }

  .medium-up-1 {
    flex-wrap: wrap;
  }

  .medium-up-1 > .column,
  .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-up-2 {
    flex-wrap: wrap;
  }

  .medium-up-2 > .column,
  .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-up-3 {
    flex-wrap: wrap;
  }

  .medium-up-3 > .column,
  .medium-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .medium-up-4 {
    flex-wrap: wrap;
  }

  .medium-up-4 > .column,
  .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-up-5 {
    flex-wrap: wrap;
  }

  .medium-up-5 > .column,
  .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .medium-up-6 {
    flex-wrap: wrap;
  }

  .medium-up-6 > .column,
  .medium-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .medium-up-7 {
    flex-wrap: wrap;
  }

  .medium-up-7 > .column,
  .medium-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }

  .medium-up-8 {
    flex-wrap: wrap;
  }

  .medium-up-8 > .column,
  .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .row {
    &.medium-unstack {
      > .column,
      > .columns {
        flex: 1 1 0px;
      }
    }
  }

  .medium-collapse {
    > .column,
    > .columns {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .medium-uncollapse {
    > .column,
    > .columns {
      padding-right: $padding-sm; // 15px
      padding-left: $padding-sm; // 15px
    }
  }
}

/* Wider than 1024px */
@media print, screen and (min-width: $breakpoint-large) {
  .row {
    &.large-unstack {
      > .column,
      > .columns {
        flex: 1 1 0px;
      }
    }
  }

  .large-expand {
    flex: 1 1 0px;
  }

  .large-collapse {
    > .column,
    > .columns {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .large-uncollapse {
    > .column,
    > .columns {
      padding-right: $padding-sm; // 15px
      padding-left: $padding-sm; // 15px
    }
  }

  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .large-offset-1 {
    margin-left: 8.33333%;
  }

  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-offset-2 {
    margin-left: 16.66667%;
  }

  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .large-offset-4 {
    margin-left: 33.33333%;
  }

  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .large-offset-5 {
    margin-left: 41.66667%;
  }

  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .large-offset-7 {
    margin-left: 58.33333%;
  }

  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .large-offset-8 {
    margin-left: 66.66667%;
  }

  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .large-offset-10 {
    margin-left: 83.33333%;
  }

  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .large-offset-11 {
    margin-left: 91.66667%;
  }

  .large-order-1 {
    order: 1;
  }

  .large-order-2 {
    order: 2;
  }

  .large-order-3 {
    order: 3;
  }

  .large-order-4 {
    order: 4;
  }

  .large-order-5 {
    order: 5;
  }

  .large-order-6 {
    order: 6;
  }

  .large-up-1 {
    flex-wrap: wrap;
  }

  .large-up-1 > .column,
  .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }

  .large-up-2 > .column,
  .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .large-up-3 {
    flex-wrap: wrap;
  }

  .large-up-3 > .column,
  .large-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .large-up-4 {
    flex-wrap: wrap;
  }

  .large-up-4 > .column,
  .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-up-5 {
    flex-wrap: wrap;
  }

  .large-up-5 > .column,
  .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .large-up-6 {
    flex-wrap: wrap;
  }

  .large-up-6 > .column,
  .large-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .large-up-7 {
    flex-wrap: wrap;
  }

  .large-up-7 > .column,
  .large-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column,
  .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.875rem;

  &:last-child {
    margin-bottom: 0;
  }
}
