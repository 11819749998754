[disabled],
.not-clickable {
  cursor: not-allowed !important;
}

/*
  Seems like better place to keep these generic styles rather than keep it in _forms.scss
*/

[hidden] {
  display: none !important;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}
