@use './settings' as *;

.browser-warning {
  background: $white;
  padding: $padding-md $padding-sm;
  position: fixed;
  height: 100%;
  text-align: center;
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    padding: $padding-lg;
  }

  h1 {
    font: normal 24px/34px $sans-serif;
    margin-bottom: $padding-md;

    @media screen and (min-width: $breakpoint-medium) {
      font: normal 36px/50px $sans-serif;
    }
  }

  h2 {
    font: normal 600 18px/25px $sans-serif;
    margin-bottom: $padding-md;

    @media screen and (min-width: $breakpoint-medium) {
      font: normal 600 21px/25px $sans-serif;
      margin-bottom: $padding-lg;
      padding: 0 15%;
    }
  }

  p {
    font: normal 15px/21px $sans-serif;
    margin-bottom: $padding-md;

    @media screen and (min-width: $breakpoint-medium) {
      font: normal 18px/25px $sans-serif;
      margin-bottom: $padding-md;
      padding: 0 15%;
    }
  }

  .browser-list {
    li {
      a {
        color: $blue;
        display: block;
        font-size: 13px;
        text-align: center;
        width: 64px;

        @media screen and (min-width: $breakpoint-medium) {
          font-size: 16px;
          width: 128px;
        }

        &:hover {
          color: $blue-dark;
        }

        span {
          display: none;

          @media screen and (min-width: $breakpoint-medium) {
            display: inline;
          }
        }

        img {
          margin: auto;

          @media screen and (min-width: $breakpoint-medium) {
            height: 100px;
            margin-bottom: 5px;
            width: 100px;
          }
        }
      }
    }
  }
}
