@use './settings' as *;

.input-message {
  color: $gray;
  font-size: 16px;
  line-height: 1;
  margin: 5px 0 $padding-sm;

  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }
}
