.confetti {
  background-size: 75% 75%;
  background-image: url(images/confetti.svg);
}

@media (prefers-reduced-motion: reduce) {
  .confetti {
    background-image: none !important;
  }
}
