@use './settings' as *;

/*  Loaders  */
$pendulum-frequency: 1s;

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;

  &-memlet {
    height: auto;
    width: auto;
  }

  .loader-pendulums {
    height: 150px;
    width: 150px;

    #loader-frame {
      fill: $gray-darker;
    }

    #pendulum-left {
      animation: pendulum-left $pendulum-frequency cubic-bezier(0.6, 0, 0.6, 1) 0s infinite;
      transform-origin: 47px 52px;
      transform: rotate(25deg);
    }

    #pendulum-right {
      animation: pendulum-right $pendulum-frequency cubic-bezier(0.6, 0, 0.6, 1) 0s infinite;
      transform-origin: 101px 52px;
    }
  }
}

.card-loader {
  animation: rotate 0.5s infinite linear;
  height: 60px;
  width: 60px;
  margin:auto;
  padding: $padding-sm;
}

@keyframes pendulum-left {
  0%, 100% { transform: rotate(25deg); }
  25%, 69% { transform: rotate(0deg); }
}

@keyframes pendulum-right {
  0%, 19%, 75% { transform: rotate(0deg); }
  50% { transform: rotate(-25deg); }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
$pendulum-frequency: 1s;
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  &-memlet {
    height: auto;
    width: auto;
  }
  .loader-pendulums {
    height: 150px;
    width: 150px;
    #loader-frame {
      fill: $gray-darker;
    }
    #pendulum-left {
      animation: pendulum-left $pendulum-frequency cubic-bezier(0.6, 0, 0.6, 1)
        0s infinite;
      transform-origin: 47px 52px;
      transform: rotate(25deg);
    }
    #pendulum-right {
      animation: pendulum-right $pendulum-frequency cubic-bezier(0.6, 0, 0.6, 1)
        0s infinite;
      transform-origin: 101px 52px;
    }
  }
}
.card-loader {
  animation: rotate 0.5s infinite linear;
  height: 60px;
  width: 60px;
  margin: auto;
  padding: $padding-sm;
}
@keyframes pendulum-left {
  0%,
  100% {
    transform: rotate(25deg);
  }
  25%,
  69% {
    transform: rotate(0deg);
  }
}
@keyframes pendulum-right {
  0%,
  19%,
  75% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-25deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
