@use "./settings" as *;

html {
  box-sizing: border-box;
  font-size: $base-font-size;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background: $gray-dark;
  color: $gray-darker;
  font: 400 1rem/1 $sans-serif;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body,
#react {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
dl,
ol,
ul {
  margin: 0;
  padding: 0;
}
main,
li {
  display: block;
}
h1,
h2,
h3,
h4 {
  font-size: inherit;
}
a,
button {
  color: inherit;
  transition: $transition-short;
}
a {
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $blue;
  }
}
button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
::-moz-focus-inner {
  padding: 0;
  border: 0;
}
img,
video {
  border: 0;
  display: block;
  height: auto;
  max-width: 100%;
  outline: 0;
  width: 100%;
}
abbr[title] {
  text-decoration: none;
}

#content,
#react {
  &:focus {
    outline: 0; // Forces no outline on react container
  }
}
