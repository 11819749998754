@use './settings' as *;

.react-tabs {
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    padding: 0;
  }

  &__tab {
    color: $gray-darker;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    list-style: none;
    margin: 0 $padding-sm;
    padding: 0 0 $padding-sm;
    text-transform: capitalize;

    &:first-child {
      margin-left: 0;
    }

    &--selected {
      color: $blue-dark;
      border-bottom: 2px solid $blue-dark;

      &:hover,
      &:focus {
        border-color: $blue;
      }
    }

    &--disabled {
      color: $gray;
      cursor: default;
    }

    &:focus,
    &:hover {
      color: $blue-dark;
    }
  }

  &__tab-panel {
    display: none;
    width: 100%;

    &--selected {
      display: block;
    }
  }
}

.fullscreen {
  .react-tabs {
    &__tab {
      padding: $padding-sm 0;
    }

    &__tab-list {
      background: $gray-lightest;
      padding: 0 $padding-md;
    }

    &__tab-panel {
      display: none;

      &--selected {
        display: block;
      }
    }
  }
}
