@use './settings' as *;

.image-container {
  figure {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
  }

  img {
    display: block;
    line-height: 0;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;
    visibility: hidden !important;
  }

  .image-annotation-marker {
    background: $gray-darker;
    border: 2px solid $white;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    color: $white;
    display: block;
    font: normal 700 15px/30px $sans-serif;
    position: absolute;
    text-align: center;
    width: $annotation-marker-width;
    height: $annotation-marker-height;
  }

  .image-annotation-marker-hover {
    background: $blue;
    border-color: $blue;
    cursor: pointer;
  }
}
