@use '../settings' as *;

/* Color Overrides */
$evergreen-green-lightest: #E8F4DF;
$evergreen-green-light: #C1E1A9;
$evergreen-green: #63B529;
$evergreen-green-dark: #2D3D34;
$evergreen-green-darker: #1F2B25;


/* Theme overrides */
.evergreen {
  background: $evergreen-green-dark;

  /* BEGIN: Buttons */
  .btn-black {
    background: $evergreen-green-darker;
  }
  /* END: Buttons */

  /* BEGIN: Lozenge List */
  ol.list-lozenges {
    .list-lozenges-item {
      &:before {
        background: $evergreen-green-light;
      }

      .list-lozenges-item-inner {
        background: $white;
      }

      &:focus,
      &:hover {
        &:before {
          background: $evergreen-green;
        }

        .list-lozenges-item-inner {
          background: $evergreen-green-lightest;
        }
      }
    }
  }
  /* END: Lozenge List */

  /* BEGIN: Topbar */
  .topbar {
    background: $evergreen-green-darker;

    .list-inline {
      > li {
        > a, button {
          color: $gray-lightish;
        }
      }
    }
  }
  /* END: Topbar */
}
