@use './settings' as *;
/*  Visibility Classes  */
.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: $breakpoint-extra-small) {
  .hide-for-extra-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: $breakpoint-small) {
  .hide-for-small {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-small - 1) and (min-width: $breakpoint-extra-small) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: $breakpoint-medium) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-medium - 1) and (min-width: $breakpoint-small) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: $breakpoint-large) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-large - 1) and (min-width: $breakpoint-medium) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (min-width: $breakpoint-extra-small - 1) {
  .show-for-extra-small-only {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-extra-small - 1) {
  .show-for-small {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-extra-small - 1),
  screen and (min-width: $breakpoint-small) {
  .show-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-medium - 1) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-small - 1),
  screen and (min-width: $breakpoint-medium) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-medium - 1) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-medium - 1),
  screen and (min-width: $breakpoint-large) {
  .show-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: $breakpoint-large - 1) {
  .show-for-extra-large {
    display: none !important;
  }
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;

  @media screen and (orientation: landscape) {
    display: block !important;
  }

  @media screen and (orientation: portrait) {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;

  @media screen and (orientation: landscape) {
    display: none !important;
  }

  @media screen and (orientation: portrait) {
    display: block !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.show-on-focus:active,
.show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}
