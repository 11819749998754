/* Styles for page headers */
@use './settings' as *;

.page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $gray-lightish;
  padding-top: $padding-sm;
  padding-bottom: $padding-sm;

  @media screen and (min-width: $breakpoint-small) {
    padding-top: $padding-md;
    padding-bottom: $padding-md;
  }
  
  .container {
    width: 100%;
  }

  .row {
    width: 100%;
    & + .row {
      margin-top: $padding-sm;
    }
  }
  
  .header-with-items {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    
    @media screen and (min-width: $breakpoint-small) {
      justify-content: center;
      flex-direction: row;
      
      .item {
        &:first-child {
          margin-right: $padding-sm*0.5;
        }
        &:last-child {
          margin-left: $padding-sm*0.5;
        }
      }
    }
  }
}
