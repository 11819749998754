@use './settings' as *;

.password-confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .password-confirm-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    width: 50%;
    max-width: 600px;
    top: 30%;
    text-align: left !important;

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .close-icon {
        cursor: pointer;
        fill: $gray-darkish;
        vertical-align: 0em !important;
      }
    }
    .confirm-password-title {
      font:
        16px normal,
        'Nunito',
        sans-serif;
      color: $gray-darkish;
      line-height: 21px;
      text-transform: uppercase;
    }

    .show-password {
      display: flex;
      justify-content: flex-end;
    }
  }

  @media (max-width: 768px) {
    .password-confirm-modal {
      width: 90%;
      margin: 20px auto;
    }
  }
}
