/*  Icons  */
@use './settings' as *;

.icon,
svg[aria-hidden='true'] {
  display: inline-block;
}

.icon {
  fill: currentColor;
  height: 1em;
  width: 1em;
  overflow: hidden;
  vertical-align: -0.15em;

  &-large {
    height: 2em;
    width: 2em;
  }

  &-large-width-only {
    width: 2em;
  }
}

.icon-leaf {
  fill: $green-leaf;
  stroke-linecap: round;
  stroke-width: 2px;
}

.icon-star {
  fill: $yellow;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke: $yellow;
}

/* These are inline help icons */
.icon-help {
  margin-left: 3px;
}

.icon-label-new {
  fill: $green-dark;
}

.emoji {
  line-height: unset;
  vertical-align: middle;
}
