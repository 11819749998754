@use 'sass:math';
@use './settings' as *;

.question {
  display: flex;
  justify-content: center;
  padding: $padding-md 0;

  @media screen and (min-width: $breakpoint-medium) {
    padding-top: $padding-lg;
  }

  .image-container {
    margin-bottom: 1rem;

    .image-annotation-marker {
      font: normal 700 $sans-serif;
      width: $annotation-marker-width;
      height: $annotation-marker-height;
    }
  }

  .constellation-container {
    position: relative;
    display: block;
    height: auto;
    max-width: 100%;
    min-height: 400px;
    width: 100%;
    margin: auto;
  }

  /* Cloze Questions */
  .cloze-question-container {
    width: 100%;
    .cloze-background {
      h1 {
        margin-bottom: 1rem;
      }
    }

    .cloze-context,
    .cloze-background,
    .cloze-definition,
    .cloze-spelling {
      text-align: center;

      h1 {
        color: $gray-light;
        font: italic 18px/22px $sans-serif;
        text-align: center;

        @media screen and (min-width: $breakpoint-small) {
          font: italic 22px/27px $sans-serif;
        }
      }

      .list-circles {
        margin-bottom: 1rem;

        .list-circles-item {
          text-align: left;
        }
      }
    }

    .cloze-buttons {
      text-align: center;

      .btn {
        margin-bottom: 5px;
        margin-right: 1.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .cloze-block,
    .cloze-inline {
      .cloze-container {
        display: inline-block;

        .letters {
          display: flex;

          $letter-padding: 6px;

          .letter {
            text-align: center;

            &:last-child {
              margin-right: 0;
            }

            input {
              background: $gray-darker;
              border: none;
              border-bottom: 1px solid $blue;
              border-radius: $corner-radius-small $corner-radius-small 0 0;
              color: $white;
              display: block;
              outline: 0;
              text-align: center;
              padding: 1px 2px;
            }

            &.ascend {
              padding-top: 0;
            }

            &.incorrect {
              color: $red;

              input {
                border-bottom-color: $red;
              }
            }

            &.misspelled {
              color: $orange;

              input {
                border-bottom-color: $orange;
              }
            }
          }
        }
      }
    }

    .cloze-block {
      .cloze-container {
        .letters {
          display: flex;
          $small-letter-padding: 3px;
          .letter {
            margin-right: 2px;
            padding-top: $small-letter-padding;
            &:last-child {
              margin-right: 0;
            }
            input {
              border-bottom-width: 2px;
              font-size: 0.8rem;
              width: 0.8rem;
              @media screen and (min-width: $breakpoint-extra-small) {
                font-size: 1.5rem;
                width: 1.5rem;
              }
              @media screen and (min-width: $breakpoint-small) {
                font-size: 2em;
                width: 2rem;
              }
              @media screen and (min-width: $breakpoint-medium) {
                font-size: 2.5rem;
                width: 2.5rem;
              }
            }

            &.ascend {
              input {
                padding-top: $small-letter-padding + 1px;
              }
            }

            &.descend {
              input {
                padding-bottom: $small-letter-padding + 1px;
              }
            }
          }
          &.long-word {
            gap: 2px;
            .letter {
              margin-right: 1px;

              @media screen and (min-width: $breakpoint-small) {
                margin-right: 2px;
              }
              input {
                font-size: 0.6rem;
                width: 0.6rem;
                @media screen and (min-width: $breakpoint-extra-small) {
                  font-size: 1.1rem;
                  width: 1.1rem;
                }
                @media screen and (min-width: $breakpoint-small) {
                  font-size: 1.1rem;
                  width: 1.1rem;
                }
                @media screen and (min-width: $breakpoint-medium) {
                  font-size: 1.25rem;
                  width: 1.25rem;
                }
                @media screen and (min-width: $breakpoint-large) {
                  font-size: 1.75rem;
                  width: 1.75rem;
                }
              }
            }
          }
        }
      }
    }

    .cloze-helpers {
      @media screen and (min-width: $breakpoint-small) {
        padding-top: $padding-lg;
      }
    }

    .cloze-hint {
      color: $white;
      font: normal 16px/24px $sans-serif;
      margin-bottom: 1rem;
      text-align: center;

      span {
        color: $gray-light;
      }
    }

    .cloze-inline {
      display: inline-block;

      .cloze-container {
        .letters {
          display: flex;

          $inline-letter-padding: 6px;

          .letter {
            margin-right: 3px;
            padding-top: $inline-letter-padding;

            &:last-child {
              margin-right: 0;
            }

            input {
              font-size: 22px;
              width: 1.25em;
              @media screen and (min-width: $breakpoint-extra-small) {
                font-size: 1.1rem;
                width: 1.1rem;
              }
              @media screen and (min-width: $breakpoint-small) {
                font-size: 1.1rem;
                width: 1.1rem;
              }
              @media screen and (min-width: $breakpoint-medium) {
                font-size: 1.25rem;
                width: 1.25rem;
              }
              @media screen and (min-width: $breakpoint-large) {
                font-size: 1.75rem;
                width: 1.75rem;
              }
            }

            &.ascend {
              input {
                padding-top: $inline-letter-padding + 1px;
              }
            }

            &.descend {
              input {
                padding-bottom: $inline-letter-padding + 1px;
              }
            }
          }
        }
      }
    }

    .cloze-inputs {
      font: normal 22px/40px $sans-serif;
      color: $white;
      margin-bottom: $padding-md;
      text-align: center;
    }

    .align-left {
      text-align: left;
    }

    .cloze-spelling {
      h1 {
        margin-bottom: 2rem;
      }
    }
  }

  /* Multi Correct Questions*/
  .mc-multicorrect {
    .list-lozenges {
      margin-bottom: $padding-sm;
    }
  }

  /* Multiple Choice Questions */
  .mc-question-container {
    margin-bottom: $padding-sm;
    width: 100%;


    .mc-key-idea3-paragraph-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
    }

    .reading-instruction {
      margin: 24px;
      font: normal 22px/22px $sans-serif;
      text-align: center;
      color: $gray-light;
      width: 100%;
      line-height: normal;
    }

    .mc-key-idea3-question-header {
      color: $white;
      font: normal 22px/27px $sans-serif;
      margin-bottom: $padding-md;
      text-align: center;
      width: 100%;
      padding: 0px 10px;
    }

    .mc-key-idea-content {
      color: $white;
      background-color: $gray-darker;
      padding: 24px;
      line-height: 2 !important;
      margin-bottom: $padding-md;
      border-radius: 5px;
      .citation-text {
        color: $gray-lightish;
        font:
          normal 16px/16px 'Nunito',
          sans-serif;
        margin-top: 6px;
      }
    }

    .scroll-bar-poetry {
      max-height: 500px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-darkish;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: $gray-darker;
      }
    }

    .mc-key-idea4-poetry-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
    }

    .poetry-answer-option-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    
    }
    @media screen and (min-width: $breakpoint-small) {
      margin-bottom: 9rem;
    }

    .mc-question-wrap {
      .list-lozenges {
        @media screen and (min-width: $breakpoint-small) {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .list-lozenges-item {
          @media screen and (min-width: $breakpoint-small) {
            width: 40%;
            margin: math.div($padding-sm * 2, 3) $padding-sm;
          }
        }
      }
    }

    .mc-question-header {
      color: $white;
      font: normal 22px/27px $sans-serif;
      margin-bottom: $padding-md;
      text-align: center;
    }

    .align-left {
      text-align: left;
    }

    .mc-question-hints {
      color: $gray-light;
      font: normal 18px/22px $sans-serif;
      margin-bottom: $padding-sm;
      font-style: italic;
    }

    .mc-underscores {
      border-bottom: 2px solid $white;
      color: transparent;
      display: inline-block;
      width: 5rem;
    }
  }

  /* Yes/No Questions */
  .yes-no-question-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $padding-sm;
    text-align: center;
    width: 100%;

    @media screen and (min-width: $breakpoint-medium) {
      padding: $padding-lg 0;
    }

    @media screen and (min-width: $breakpoint-small) {
      margin-bottom: 3rem;
    }

    .calirabtion-word-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: $padding-lg;

      h1 {
        color: $white;
        font: normal 600 54px/1 $sans-serif;

        @media screen and (min-width: $breakpoint-medium) {
          font: normal 600 70px/1 $sans-serif;
        }

        &.long-word {
          font: normal 600 36px/1 $sans-serif;

          @media screen and (min-width: $breakpoint-medium) {
            font: normal 600 70px/1 $sans-serif;
          }
        }
      }

      .audio-icon {
        margin-left: $padding-sm * 0.5;
        margin-top: 16px;
      }
    }

    p {
      color: $gray-light;
      font: normal 400 1rem/1.5rem $sans-serif;
      padding-bottom: $padding-md;
    }

    .buttons {
      display: flex;
      justify-content: center;
    }
  }
}

.question-goal-progress {
  color: $gray-light;
  font: normal 14px/18px $sans-serif;
  padding: 0 $padding-sm;

  span {
    color: $green-brand;
    font-weight: 700;
  }
}
.question-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $padding-sm;
  color: $white;

  &.question-timer-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $gray-darker;
    z-index: 10;
  }

  .progress {
    margin: 0 auto;
    width: 70vw;

    @media screen and (min-width: $breakpoint-small) {
      max-width: 14rem;
    }
  }
}

.margin-top {
  @media screen and (max-width: $breakpoint-small) {
    margin-top: $padding-xs;
  }
}

.next-word-countdown-container {
  display: flex;
  align-items: center;
  .delayable-buttons-container {
    margin-left: 24px;
    display: flex;
    .more-time-btn {
      margin-left: 16px;
    }
    .hide-btn {
      display: none;
    }
  }
}
