@use './settings' as *;

.resource-list {
  width: 100%;
  margin-top: $padding-sm;
  
  .resource-list-item {
    padding: $padding-sm 0;
    border-top: 1px solid $gray-lightest;

    &:last-child {
      border-bottom: 1px solid $gray-lightest;
    }
  }
}