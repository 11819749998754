@use './settings' as *;

/*  Modals  */
body.modal-open {
  overflow: hidden;
}

.modal {
  background: $gray-dark;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: $padding-sm;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: 15;

  @media screen and (min-width: $breakpoint-medium) {
    padding: $padding-md * 2 0 $padding-md;
  }

  &-content {
    padding: $padding-md;
    text-align: left;

    a {
      color: $blue-dark;

      &:hover,
      &:focus {
        color: $blue;
      }
    }

    form {
      display: inline-block;
    }

    h1 {
      font-size: 2rem;
      line-height: 1;
    }

    * + p,
    * + ul,
    * + form {
      margin-top: 1.5rem;
    }
  }

  &-inner {
    background: $white;
    border-radius: $corner-radius-medium;
    margin: 0 auto $padding-md;
    position: relative;
    overflow: scroll;
    height: auto;
    width: 100%;
    max-width: 100vw;
    max-height: 80vh;

    @media screen and (min-width: $breakpoint-medium) {
      width: 80%;
    }
  }

  .btn-close {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;

    .icon-close {
      fill: $gray-light;
    }

    &:hover,
    &:focus {
      .icon-close {
        fill: $white;
      }
    }
  }
}
