@use './settings' as *;

.interstitial {
  min-height: 100vh;
  padding: $padding-sm;
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    padding: $padding-lg;
  }

  @media screen and (min-width: $breakpoint-large) {
    padding: $padding-xl $padding-lg $padding-lg;
  }

  .interstitial-inner {
    margin: 0 auto;
    text-align: center;

    @media screen and (min-width: $breakpoint-medium) {
      max-width: 612px;
    }

    .icon-interstitial {
      height: 120px;
      margin: 0 auto $padding-md;
      width: 120px;

      @media screen and (min-width: $breakpoint-medium) {
        height: 150px;
        width: 150px;
      }

      use {
        color: $blue;
        fill: $gray-darker;
        stroke: $white;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2;
      }
    }

    .interstitial-buttons {
      margin-bottom: $padding-md;

      @media screen and (min-width: $breakpoint-medium) {
        margin-bottom: $padding-lg;
      }

      .btn {
        margin-right: $padding-sm;

        @media screen and (max-width: $breakpoint-small) {
          display: block;
          margin: 12px auto;
          text-align: center;
        }

        &:last-child {
          margin-right: 0;

          @media screen and (max-width: $breakpoint-small) {
            margin-right: auto;
          }
        }
      }
    }

    .interstitial-content {
      color: $gray-light;
      font: normal 17px/1.4 $sans-serif;
      margin-bottom: $padding-md;

      @media screen and (min-width: $breakpoint-medium) {
        font: normal 20px/1.4 $sans-serif;
      }

      small {
        color: $gray-lightish;
        font-size: 12px;
      }

      strong,
      b {
        color: $white;
        font-weight: 600;
      }
    }

    .interstitial-intro {
      color: $gray-light;
      font: normal 20px/1.4 $sans-serif;
      margin-bottom: $padding-md;

      @media screen and (min-width: $breakpoint-medium) {
        font: normal 28px/1.3 $sans-serif;
        margin-bottom: $padding-lg;
      }

      strong,
      b {
        color: $white;

        @media screen and (min-width: $breakpoint-medium) {
          display: inline;
        }
      }
    }

    .interstitial-title {
      color: $white;
      font: normal 600 31px/1.2 $sans-serif;
      margin-bottom: $padding-sm;

      @media screen and (min-width: $breakpoint-medium) {
        font: normal 600 50px/1 $sans-serif;
        margin-bottom: $padding-sm;
      }
    }
  }
}
