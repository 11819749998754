@use './settings' as *;

.dropdown {
  display: inline-block;
  overflow: visible;
  position: relative;

  a, button {
    &:focus,
    &:hover {
      .icon {
        fill: $blue;
      }
    }
  }

  .dropdown-menu {
    background: $gray-darkest;
    border-radius: 6px;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.4);
    color: $white;
    left: 0;
    list-style: none;
    margin: 0;
    max-width: 320px;
    padding: $padding-xs 0;
    position: absolute;
    top: calc(100% + #{$padding-sm});
    z-index: 99999;

    a, button {
      color: $white;
      display: inline-block;
      font: normal 18px/36px $sans-serif;
      padding: 0 $padding-sm;

      &:focus,
      &:hover {
        color: $blue;
      }

      .icon {
        height: 22px;
        margin-right: 16px;
        vertical-align: -4px;
        width: 22px;
      }
    }

    .dropdown-menu-item {
      line-height: initial;
      white-space: nowrap;
    }
  }
  .dropdown-arrow {
    border-bottom: $padding-sm solid $gray-darkest;
    border-left:  $padding-sm solid transparent;
    border-right:  $padding-sm solid transparent;
    display: block;
    position: absolute;
    top: -$padding-sm;
  }

}

.dropdown-align-left {
  .dropdown-arrow {
    left: $padding-sm;
  }
}

.dropdown-align-right {
  .dropdown-arrow {
    right: $padding-sm;
  }
  .dropdown-menu {
    left: auto;
    right: 0px;
  }
}
