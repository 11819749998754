/* 
* 
* Apply to parent of any footer to make footer stick to bottom of page when vertical content is minimal
* 
* <div class="container footer-sticky">
*   <header/>
*   <main id="content"/>
*   <footer/>
* </div>
*/

@use './settings' as *;

.footer-sticky {
  display: flex;
  height: 100%;
  flex-direction: column;

  #content {
    flex: 1;
  }
}

.footer {
  background: $gray-darker;
  color: $gray-light;
  flex-shrink: 0;

  a {
    &:focus,
    &:hover {
      color: $green-brand;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 20px;

    @media screen and (min-width: $breakpoint-medium) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      padding: $padding-lg 20px;
    }
  }

  .footer-back-to-top {
    order: 1;

    @media screen and (min-width: $breakpoint-medium) {
      order: 2;
    }

    a,
    button {
      display: inline-block;
      font: normal 600 16px/16px $sans-serif;
      margin-bottom: 40px;
      text-align: center;

      @media screen and (min-width: $breakpoint-medium) {
        margin-bottom: 0;
      }

      .icon {
        display: block;
        height: 31px;
        margin: 0 auto 16px;
        width: 31px;
      }

      &:focus,
      &:hover {
        color: $green-brand;

        .icon {
          fill: $green-brand;
        }
      }
    }
  }

  .footer-copyright {
    font: normal 600 16px/22px $sans-serif;
    order: 2;

    @media screen and (min-width: $breakpoint-medium) {
      order: 1;
    }

    p {
      font: normal 600 16px/22px $sans-serif;
    }
  }

  .list-inline {
    margin-top: 20px;
  }
}
