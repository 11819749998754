@use './settings' as *;

/* Alerts */
.alert {
  display: flex;
  align-items: center;
  background: $gray-darker;
  border-bottom: 1px solid $gray-darker;
  color: $gray-lightest;
  justify-content: center;
  font: normal 18px/24px $sans-serif;
  height: auto;
  position: relative;
  padding: $padding-sm;

  @media screen and (min-width: $breakpoint-small) {
    font: normal 21px/29px $sans-serif;
  }

  @media screen and (max-width: $breakpoint-small) {
    .container .row {
      flex-direction: column !important;
      .alert-text {
        margin-bottom: 8px;
      }
    }
  }

  .container {
    width: 100%;

    .row {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      p {
        margin: 0 auto;
      }

      .alert-text {
        flex: 1;
        font-size: 16px;
        line-height: 1.5em;
      }

      .alert-actions {
        display: flex;
        flex-direction: row;
      }
    }
  }

  form {
    margin: 0 auto;
    text-align: center;

    button {
      font-size: 1rem;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $blue;
      }
    }
  }

  p {
    line-height: 1.2rem !important;
  }

  &.no-margin {
    margin: 0 !important;
  }

  .alert-close-btn {
    display: flex;
    align-items: center;
    padding: $padding-sm * 0.5;

    .icon-close {
      fill: $gray-lightest;
      height: 0.8rem;
      width: 0.8rem;

      &:focus,
      &:hover {
        fill: $green-brand;
      }
    }
  }

  .btn-alert-action {
    background: transparent !important;
    border: 1px solid $gray;
    color: $gray;
    font-size: 14px !important;
    line-height: 14px !important;
    padding: 7px 17px 8px;
    margin: 0 0.5em;
  }

  strong {
    margin-right: $padding-sm * 0.5;
  }

  a:not(.btn) {
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  kbd {
    background: $gray-darkest;
    border: 1px solid $gray-lightest;
    border-radius: 6px;
    color: $gray-lightest;
    display: inline-block;
    font-size: 15px;
    line-height: 23px;
    padding: 0 7px;
    vertical-align: 2px;
  }

  .alert-title {
    color: $white;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;

    @media screen and (min-width: $breakpoint-small) {
      font-size: 18px;
    }
  }
}

/*  Alert Colors  */
.alert-alert {
  background: $tan;
  color: $gray-darker;

  form {
    button {
      &:hover,
      &:focus {
        color: $tan-darkest;
      }
    }
  }

  a:not(.btn) {
    &:hover,
    &:focus {
      color: $tan-dark;
    }
  }

  .alert-title {
    color: $gray-darkish;
  }

  .alert-close-btn {
    .icon-close {
      fill: $tan-darkest;
    }
    &:hover,
    &:focus {
      .icon-close {
        fill: $tan-dark;
      }
    }
  }

  .btn-alert-action {
    border-color: $tan-darkest;
    color: $tan-darkest;

    &:hover,
    &:focus {
      background-color: $tan-darkest !important;
      color: $white;
    }
  }
}

.alert-contest {
  background: $blue-dark;
  color: $white;

  form {
    button {
      &:hover,
      &:focus {
        color: $gray-lighter;
      }
    }
  }

  .alert-title {
    color: $white;
  }

  .alert-close-btn {
    .icon-close {
      fill: $white;
    }
    &:hover,
    &:focus {
      .icon-close {
        fill: $gray-lighter;
      }
    }
  }

  .btn-alert-action {
    background: $blue-dark !important;
    border-color: $white;
    color: $white;

    &:hover,
    &:focus {
      background-color: $blue-dark !important;
      border-color: $gray-lighter;
      color: $gray-lighter;
    }
  }
}

.alert-error {
  background: $red-light;
  color: $gray-darker;

  form {
    button {
      &:hover,
      &:focus {
        color: $red-dark;
      }
    }
  }

  a {
    &:hover,
    &:focus {
      color: $red-dark;
    }
  }

  .alert-title {
    color: $red-dark;
  }

  .alert-close-btn {
    .icon-close {
      fill: $red-dark;
    }
    &:focus,
    &:hover {
      .icon-close {
        fill: $red;
      }
    }
  }

  .btn-alert-action {
    border-color: $red-dark;
    color: $red-dark;

    &:hover,
    &:focus {
      background-color: $red-dark !important;
      color: $white;
    }
  }
}

.alert-info {
  background: $blue-lightest;
  color: $gray-dark;

  form {
    button {
      &:hover,
      &:focus {
        color: $blue-dark;
      }
    }
  }

  .alert-title {
    color: $gray-dark;
  }

  .alert-close-btn {
    .icon-close {
      fill: $gray-dark;
    }
    &:hover,
    &:focus {
      .icon-close {
        fill: $blue;
      }
    }
  }

  .btn-alert-action {
    border-color: $blue;
    color: $blue;

    &:hover,
    &:focus {
      background-color: $blue-dark !important;
      border-color: $blue-dark;
      color: $white;
    }
  }
}

.alert-success {
  background: $green-light;
  color: $gray-darker;

  form {
    button {
      &:hover,
      &:focus {
        color: $green-dark;
      }
    }
  }

  a {
    &:hover,
    &:focus {
      color: $green-dark;
    }
  }

  .alert-title {
    color: $green-dark;
  }

  .alert-close-btn {
    .icon-close {
      fill: $green-dark;
    }
    &:hover,
    &:focus {
      .icon-close {
        fill: $green;
      }
    }
  }

  .btn-alert-action {
    border-color: $green-dark;
    color: $green-dark;

    &:hover,
    &:focus {
      background-color: $green !important;
      border-color: $green;
      color: $white;
    }
  }
}

/*  Variants  */
.alert-bottom,
.alert-top {
  border-radius: 0 !important;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 999;
}

.alert-bottom {
  border-bottom: none;
  border-top: 1px solid $gray-darker;
  bottom: 0;

  &:focus {
    border-top: 2px solid $orange;
    outline: none;
  }
}

.alert-top {
  position: sticky;
  top: 0;
  z-index: 2;

  &:focus {
    border-bottom: 2px solid $orange;
    outline: none;
  }
}

.alert-full {
  border-radius: 0 !important;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
  left: 0;
  width: 100vw;
  z-index: 9999;
  position: absolute;
  top: 50%;
  margin-top: -50px;
}

.alert-inline {
  border: none;
  border-radius: $corner-radius-small;
  display: block;
  margin-bottom: $padding-sm;
  padding: $padding-xs $padding-sm $padding-xs 2rem !important;
  width: 100%;

  ul {
    font-size: 16px;
    line-height: 24px;
    list-style-type: disc;
    margin-block: 1em;
    padding-inline-start: 1em;

    li {
      display: list-item;
    }
  }

  &:before {
    background-position: 0.25rem center;
    background-repeat: no-repeat;
    background-size: 55% 55%;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 1rem;
    left: 0.75rem;
    line-height: 1rem;
    position: absolute;
    top: 0.75rem;
    width: 1rem;
  }

  &.alert-error {
    color: $red-dark;

    &:before {
      background-color: $red-dark;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3IDIxIj4KICA8cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0zLjUwMyAxNS4yMDFjMS42NjkgMCAzLjAyOSAxLjI1MSAzLjAyOSAyLjc2MyAwIDEuNTA4LTEuMzU3IDIuNzM2LTMuMDMgMi43MzYtMS42MjQgMC0yLjk3LTEuMjM4LTIuOTctMi43MzYgMC0xLjUwMSAxLjM0OS0yLjc2MyAyLjk3LTIuNzYzek01LjEzNy43Yy41ODIgMCAxLjA4NS40MiAxLjE1NC45MzlsLjAwOC4xMi0uMDA2IDMuMDU3LTEuMTM3IDcuNDdjLS4wNjMuNDYtLjUxOC44NDctMS4wMjkuOTAzTDQgMTMuMTk3aC0uOTM0Yy0uNTE3IDAtMS4wMDctLjM1Mi0xLjEzMy0uNzkybC0uMDIzLS4xMTItMS4xNDQtNy41VjEuNzZjMC0uNTMxLjQ2MS0uOTkgMS4wMy0xLjA1M0wxLjkyNy43aDMuMjF6Ii8+Cjwvc3ZnPgo=);
    }
  }

  &.alert-info {
    color: $blue-dark;

    &:before {
      background-color: $blue-dark;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDIxIj4KICA8cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0zLjc5IDUuMTg3Yy42MTQgMCAxLjE0LS4yMTYgMS41NzctLjY0OS40MzctLjQzMi42NTYtLjk1NS42NTYtMS41NyAwLS42MTUtLjIxNi0xLjE0LS42NDktMS41NzhBMi4xNDQgMi4xNDQgMCAwMDMuNzkuNzM0Yy0uNjE0IDAtMS4xMzguMjE5LTEuNTcuNjU2YTIuMTYzIDIuMTYzIDAgMDAtLjY0OSAxLjU3OGMwIC42MTUuMjE2IDEuMTM4LjY0OSAxLjU3YTIuMTQgMi4xNCAwIDAwMS41Ny42NDl6bTMuNjQ1IDE1LjU0N3YtLjUxOGMtLjY0NC0uMDM5LTEuMDc2LS4xOS0xLjI5Ny0uNDU0LS4yMi0uMjY0LS4zMzEtLjg3Mi0uMzMxLTEuODIzVjcuMjYySC4xNTh2LjUzM2MuNjM0LjAzOCAxLjA2Mi4xOSAxLjI4My40NTQuMjIuMjY0LjMzMS44NzIuMzMxIDEuODIzdjcuODY3YzAgLjk1MS0uMDk2IDEuNTM3LS4yODggMS43NTgtLjI4OC4zMjctLjczLjUtMS4zMjYuNTE5di41MThoNy4yNzd6Ii8+Cjwvc3ZnPgo=);
    }
  }

  &.alert-success {
    color: $green-dark;

    &:before {
      background-color: $green-dark;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
    }
  }
}

#question-feedback-alerts {
  text-align: center;
}

/* Dashboard Alerts (for quizzes and assignments) */
#dashboard-alerts {
  /* This is the modal backdrop */
  align-items: center;
  background-color: $gray-dark;
  background-color: rgba(42, 56, 51, 0.9);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;

  &-close {
    position: absolute;
    right: $padding-sm;
    top: $padding-sm;

    @media only screen and (min-width: $breakpoint-medium) {
      right: $padding-md;
      top: $padding-md;
    }
  }

  &-container {
    /* This is the actual modal window */
    background-color: $tan;
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: $padding-sm;
    position: relative;

    @media only screen and (min-width: $breakpoint-medium) {
      border: 1px solid $tan-dark;
      border-radius: $corner-radius;
      height: auto;
      max-width: 80%;
      padding: $padding-md;
    }

    @media only screen and (min-width: $breakpoint-large) {
      max-width: 50%;
    }

    ul {
      li {
        padding-top: $padding-sm;

        > p {
          font-weight: 600;
          padding-right: $padding-md;
        }

        .btn {
          background: none;
          border: 1px solid $tan-dark;
          border-radius: $corner-radius-large;
          font-size: 1rem;
          line-height: 1.5;
          white-space: pre-wrap;
          width: 100%;

          &:focus,
          &:hover {
            background-color: $tan-dark;
            color: $tan;
          }
        }
      }
    }
  }

  &-title {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: $padding-sm;
  }
}

/* Fullscreen */
.fullscreen {
  .alert {
    padding: 20px;

    @media screen and (min-width: $breakpoint-small) {
      padding: $padding-md;
    }

    @media screen and (min-width: $breakpoint-large) {
      padding: $padding-md $padding-xl;
    }
  }

  .alert-dismissable {
    padding: 40px 20px 20px;

    @media screen and (min-width: $breakpoint-small) {
      padding: 20px 58px 20px 20px;
    }

    @media screen and (min-width: $breakpoint-medium) {
      padding: 30px 78px 30px 30px;
    }
  }
}

/* Keyboard Shortcuts */
#keyboard-shortcuts {
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: $padding-sm;
    h2 {
      margin: 0 auto;
    }
    button {
      position: absolute;
      right: 0;
    }
  }

  .list-inline {
    > li {
      margin: $padding-sm;
    }
  }

  .no-shortscuts-message {
    margin-top: -20px;
    margin-bottom: 10px;
  }
}
