@use './settings' as *;

#promotional-modal {
    /* This is the modal backdrop */
    align-items: center;
    background-color: $gray-dark;
    background-color: rgba(42, 56, 51, 0.9);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.8);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
  
    &-close {
      position: absolute;
      right: $padding-sm;
      top: $padding-sm;
  
      @media only screen and (min-width: $breakpoint-medium) {
        right: $padding-md;
        top: $padding-md;
      }
    }
  
    &-container {
      /* This is the actual modal window */
      background-color: $white;
      display: inline-block;
      height: 100%;
      width: 100%;
      padding: $padding-sm;
      position: relative;
  
      @media only screen and (min-width: $breakpoint-medium) {
        border: 1px solid $tan-dark;
        border-radius: $corner-radius;
        height: auto;
        max-width: 90%;
        padding: $padding-md;
      }
  
      @media only screen and (min-width: $breakpoint-large) {
        max-width: 75%;
      }

      p {
        margin-block-start: $padding-md;
      }

      ul {
        margin-block-start: $padding-md;
        list-style: disc;

        li {
          padding-left: $padding-md;
          position: relative;

          &:before {
            color: $green-brand;
            content: '✅';
            display: inline-block;
            font-size: 90%;
            left: 0;
            position: absolute;
            top: -1px;
          }
        }
      }

      a {
        color: $blue-dark;

        &:hover {
          color: $blue;
          text-decoration: underline;
        }
      }
}
  
    &-title {
      font-size: 1.5rem;
      line-height: 1;
      margin-bottom: $padding-md;
    }
  }