@use './settings' as *;

$progress-bar-height: 10px;
$progress-bar-radius: 5px;

/* PROGRESS BAR */

.progress {
  position: relative;

  .progress-bar {
    background-color: $gray-lightest;
    border-radius: $progress-bar-radius;
    overflow: hidden;
    width: 100%;

    .progress-meter {
      background-color: $black;
      height: $progress-bar-height;
      position: relative;
      transition: width 0.1s;
    }

    &:focus {
      box-shadow: 0 0 0 0.2em $orange;
      outline: none;
    }

    &:hover {
      .progress-meter {
        background-color: $blue;
      }

      & ~ .progress-description.tooltip {
        background-color: $black;
        bottom: 0;
        box-sizing: border-box;
        clip: auto; /* IE6, IE7 */
        color: $white;
        display: inline-block;
        height: auto; // iPad
        left: 0;
        line-height: 1.3;
        overflow: visible;
        padding: 1rem;
        position: fixed;
        text-align: center;
        white-space: initial;
        width: 100%;
        z-index: 9999;

        @media only screen and (min-width: $breakpoint-medium) {
          border-radius: 0.25rem;
          bottom: auto;
          font-size: 80%;
          left: auto;
          padding: 0.75rem;
          position: absolute;
          width: auto;

          &:after {
            content: '';
            position: absolute;
          }

          &.tooltip-bottom,
          &.tooltip-top {
            left: 50%;
            transform: translateX(-50%);
            
            &:after {
              border-left: 0.5rem solid transparent;
              border-right: 0.5rem solid transparent;
              left: calc(50% - 0.5rem);
            }
          }

          &.tooltip-bottom {
            top: 1.75em;

            &:after {
              border-bottom: 0.5rem solid $black;
              border-top: none;
              top: -0.5rem;
            }
          }

          &.tooltip-top {
            bottom: 1.75em;

            &:after {
              border-bottom: none;
              border-top: 0.5rem solid $black;
              bottom: -0.5rem;
            }
          }

          &.tooltip-left,
          &.tooltip-right {
            top: 50%;
            transform: translateY(-50%);

            &:after {
              border-bottom: 0.5rem solid transparent;
              border-top: 0.5rem solid transparent;
              top: calc(50% - 0.5rem);
            }
          }

          &.tooltip-left {
            right: calc(100% + 0.75rem);

            &:after {
              border-left: 0.5rem solid $black;
              border-right: none;
              right: -0.5rem;
            }
          }

          &.tooltip-right {
            left: calc(100% + 0.75rem);

            &:after {
              border-left: none;
              border-right: 0.5rem solid $black;
              left: -0.5rem;
            }
          }
        }
      }
    }
  }

  &.blue {
    .progress-bar {
      .progress-meter {
        background-color: $blue-dark;
      }

      &:focus,
      &:hover {
        .progress-meter {
          background-color: $blue;
        }
      }
    }
  }

  &.gray {
    .progress-bar {
      background-color: $gray-light;
      .progress-meter {
        background-color: $gray;
      }
    }
  }

  &.opacity {
    opacity: 0.4;
  }

  .progress-description {
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    color: $black;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; /* added line */
    width: 1px;
  }
}
