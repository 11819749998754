@use './settings' as *;

.option-list-container {
  width: 100%;

  .option-list-meta {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
  }

  .option-list {
    padding: $padding-sm 0 0;

    .option-list-item {
      margin-bottom: 10px;
      transition: box-shadow 0.1s ease-in-out;

      &.padding {
        padding: $padding-sm;
      }

      > a, > div {
        border: 1px solid $gray-lightest;
        border-left: 2px solid $gray-light;
        border-radius: $corner-radius;
        display: block;
        padding: $padding-sm;
        color: $gray-dark;
      }

      > a {
        &:hover,
        &:focus {
          background: $gray-hover;
          border-color: $gray-light;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .content {
        display: flex;
        justify-content: space-between;

        p {
          font-size: 14px;
          line-height: 21px;

          em {
            font-style: italic;
            font-weight: normal;
          }
        }

        .meta {
          padding-right: $padding-sm;

          h3 {
            font-weight: 600;
          }
        }

        .status {
          padding-left: $padding-sm;
          text-align: right;

          p {
            font-weight: 600;
          }

          .status-label {
            color: $gray-darker;
            font-weight: 600;
            line-height: 27px;
          }

          .score {
            color: $gray-darker;
            font-weight: 600;
            font-size: $base-font-size;
            line-height: 27px;
          }
        }
      }

      &.orange {
        > a, > div {
          border-left: 2px solid $orange;
        }
      }

      &.blue {
        > a, > div {
          border-left: 2px solid $blue-dark;
        }
      }

      &.red {
        > a, > div {
          border-left: 2px solid $red-dark;
        }
      }
    }
  }
}

/* TODO: Mobile styles */
.fullscreen {
  .option-list-container {
    .option-list-meta {
      padding: $padding-sm $padding-md;
    }

    .option-list {
      padding: $padding-sm;
      .option-list-item {
        margin-bottom: $padding-sm;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
