@use '../settings' as *;

/* Color Overrides */
$eggplant-purple-lightest: #F5E8FD;
$eggplant-purple-light: #E7CAFA;
$eggplant-purple: #BA68F2;
$eggplant-purple-dark: #3F3545;
$eggplant-purple-darker: #2C2530;


/* Theme overrides */
.eggplant {
  background: $eggplant-purple-dark;

  /* BEGIN: Buttons */
  .btn-black {
    background: $eggplant-purple-darker;
  }
  /* END: Buttons */

  /* BEGIN: Lozenge List */
  ol.list-lozenges {
    .list-lozenges-item {
      &:before {
        background: $eggplant-purple-light;
      }

      .list-lozenges-item-inner {
        background: $white;
      }

      &:focus,
      &:hover {
        &:before {
          background: $eggplant-purple;
        }

        .list-lozenges-item-inner {
          background: $eggplant-purple-lightest;
        }
      }
    }
  }
  /* END: Lozenge List */

  /* BEGIN: Topbar */
  .topbar {
    background: $eggplant-purple-darker;

    .list-inline {
      > li {
        > a, button {
          color: $gray-lightish;
        }
      }
    }
  }
  /* END: Topbar */
}
