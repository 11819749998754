@use './settings' as *;

.collapsible {
  &-content {
    width: 100%;
    height: 0;
    overflow: hidden;

    &.open {
      height: auto;
    }
  }

  &-header {
    width: 100%;

    h2 {
      button {
        background-position: right $padding-md center;
        background-repeat: no-repeat;
        background-size: 15px 9px;
        color: $gray-darkish;
        font: 14px normal, $sans-serif;
        line-height: 21px;
        padding: $padding-sm $padding-md;
        text-align: left;
        text-transform: uppercase;
        width: 100%;

        &[aria-expanded='true'] {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTUgOSI+PGRlZnMvPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNNy4wNyAyLjgyOEwxLjQxNCA4LjQ4NSAwIDcuMDcgNi4zNjQuNzA3IDcuMDcgMGw3LjA3MiA3LjA3LTEuNDE0IDEuNDE1eiIvPjwvZGVmcz48dXNlIGZpbGw9IiM1ODU5NWIiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48L3N2Zz4=);
        }

        &[aria-expanded='false'] {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTUgOSI+PGRlZnMvPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNNy4wNyA1LjY1N0wxLjQxNSAwIDAgMS40MTNsNi4zNjQgNi4zNjUuNzA3LjcwNyA3LjA3NC03LjA3TDEyLjczMSAweiIvPjwvZGVmcz48dXNlIGZpbGw9IiM1ODU5NWIiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48L3N2Zz4=);
        }
      }
    }
  }
}
