/*
  Skip links for accessible keyboard access.
  Only visible when focused.
*/
@use './settings' as *;

.skip-link-list {
  list-style: none;
  position: relative;
  z-index: 999999;
  .skip-link-list-item {
    a,
    button {
      background: $white;
      border-radius: $corner-radius-small;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
      color: $gray-darker;
      display: inline-block;
      transform: translate(-999999999px, 18px);
      padding: 0.5rem 1rem;
      position: absolute;
      width: fit-content;
      transition: 0s;

      &:focus {
        transform: translate(0px, 18px);
      }
    }
  }
}
