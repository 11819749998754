@use './settings' as *;

.element-tooltip {
  display: inline-block;
  position: relative;

  &:hover {
    & > .tooltip {
      background-color: $black;
      bottom: 0;
      box-sizing: border-box;
      clip: auto; /* IE6, IE7 */
      color: $white;
      display: inline-block;
      height: auto; // iPad
      left: 0;
      line-height: 1.3;
      overflow: visible;
      padding: 1rem;
      position: fixed;
      text-align: center;
      white-space: initial;
      width: 100%;
      z-index: 9999;

      @media only screen and (min-width: $breakpoint-medium) {
        border-radius: 0.25rem;
        bottom: auto;
        font-size: 80%;
        left: auto;
        padding: 0.5rem;
        position: absolute;

        &:after {
          content: '';
          position: absolute;
        }

        &.tooltip-top {
          left: 50%;
          transform: translateX(-50%);

          &:after {
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            left: calc(50% - 0.5rem);
          }
        }

        &.tooltip-top {
          bottom: 2em;

          &:after {
            border-bottom: none;
            border-top: 0.5rem solid $black;
            bottom: -0.5rem;
          }
        }
      }
    }
  }
}

/* DEFINITION TOOLTIP */
.dfn-tooltip {
  display: inline-block;
  position: relative;

  &-bubble {
    background-color: $gray-darkest;
    bottom: 0;
    box-sizing: border-box;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    color: $white;
    display: inline-block;
    height: 1px;
    left: 0;
    line-height: 1.3;
    overflow: hidden;
    padding: 1rem;
    position: absolute;
    text-align: left;
    white-space: nowrap; /* added line */
    width: 1px;

    .definition {
      display: block;
      font-style: normal;
      font-weight: bold;
      margin-bottom: 0.5em;
      text-transform: capitalize;
    }

    p {
      font: 0.8889rem/1.3 $sans-serif;
      margin: 0;
      line-height: 1.3;
    }

    em {
      font-weight: 400;
      font-style: italic;
    }

    strong {
      color: $white;
      font-weight: 600;
    }

    * + p {
      margin: 1em 0 0;
    }

    @media only screen and (min-width: $breakpoint-medium) {
      border-radius: 0.25rem;
      bottom: auto;
      font-size: 80%;
      left: auto;

      &:after {
        content: '';
        position: absolute;
      }

      &.tooltip-bottom,
      &.tooltip-top {
        left: 0;

        &:after {
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          left: calc(10%);
        }
      }

      &.tooltip-bottom {
        top: calc(100% + 0.5rem);

        &:after {
          border-bottom: 0.5rem solid $black;
          border-top: none;
          top: -0.5rem;
        }
      }

      &.tooltip-top {
        bottom: calc(100% + 0.5rem);

        &:after {
          border-bottom: none;
          border-top: 0.5rem solid $black;
          bottom: -0.5rem;
        }
      }

      &.tooltip-left,
      &.tooltip-right {
        top: 50%;
        transform: translateY(-50%);

        &:after {
          border-bottom: 0.5rem solid transparent;
          border-top: 0.5rem solid transparent;
          top: calc(50% - #{$padding-xs});
        }
      }

      &.tooltip-left {
        right: calc(100% + 0.75rem);

        &:after {
          border-left: 0.5rem solid $black;
          border-right: none;
          right: -0.5rem;
        }
      }

      &.tooltip-right {
        left: calc(100% + 0.75rem);

        &:after {
          border-left: none;
          border-right: 0.5rem solid $black;
          left: -0.5rem;
        }
      }
    }
  }

  &-explanation {
    border-top: 1px dotted $gray-light;
    color: $gray-lighter;
    font-size: 0.77776rem !important;
    padding-top: 1em;
  }

  &-trigger {
    font-size: 100%;
    margin: 0;
    overflow: visible;
    text-decoration: none;
    text-transform: none;

    &:focus {
      box-shadow: 0 0 0 0.2em $orange;
      outline: none;
    }

    &:focus,
    &:hover {
      color: $blue;
      text-decoration: underline;

      & ~ [role='tooltip'] {
        clip: auto; /* IE6, IE7 */
        height: auto; // iPad
        overflow: visible;
        position: fixed;
        white-space: initial;
        width: 100%;
        z-index: 9999;

        @media only screen and (min-width: $breakpoint-medium) {
          min-width: 15em;
          position: absolute;
          width: auto;
        }
      }
    }
  }
}

/* 
TODO: Remove once we figure out how to sync up tooltip position helper from uikit
*/

#expand-definitions-container {
  @media only screen and (min-width: $breakpoint-medium) {
    .tooltip {
      width: auto;

      &.tooltip-top {
        bottom: 4em;
      }
    }
  }
}

#next-trainer-action-container {
  @media only screen and (min-width: $breakpoint-medium) {
    .tooltip {
      &.tooltip-top {
        bottom: 2.75em;
      }
    }
  }
}

/*  Regular Tooltips  */
.tooltip {
  color: $gray-lighter;
  position: absolute;
  z-index: 99999;

  .tooltip-inner {
    background: $gray-darkest;
    border-radius: $corner-radius;
    font: normal 700 14px/1.3 $sans-serif;
    max-width: 15em;
    padding: $padding-sm;
    position: relative;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
    }
  }
}

.tooltip-bottom,
.tooltip-top {
  .tooltip-inner {
    &:after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      left: calc(50% - 10px);
    }
  }
}

.tooltip-bottom {
  .tooltip-inner {
    &:after {
      border-bottom: 10px solid $gray-darkest;
      border-top: none;
      top: -10px;
    }
  }
}

.tooltip-top {
  .tooltip-inner {
    &:after {
      border-bottom: none;
      border-top: 10px solid $gray-darkest;
      bottom: -10px;
    }
  }
}

.tooltip-left,
.tooltip-right {
  .tooltip-inner {
    &:after {
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      top: calc(50% - 10px);
    }
  }
}

.tooltip-left {
  .tooltip-inner {
    &:after {
      border-left: 10px solid $gray-darkest;
      border-right: none;
      right: -10px;
    }
  }
}

.tooltip-right {
  .tooltip-inner {
    &:after {
      border-left: none;
      border-right: 10px solid $gray-darkest;
      left: -10px;
    }
  }
}

.tooltip-wrap {
  margin: 0;
  padding: 0;

  &:hover {
    cursor: help;
  }
}
