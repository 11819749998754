@use './settings' as *;

/*  Typography  */
h1 {
  &:focus {
    outline: none;
  }
}

div,
input,
p,
textarea {
  font: normal 1rem/1.5rem $sans-serif;
}

// Font styles
strong,
b {
  font-weight: 700;
}

em,
i {
  font-family: $sans-serif;
  font-style: italic;
}

// Figures
figure {
  padding: $padding-sm;

  .icon {
    height: 35px;
    width: 35px;
  }
  .icon-quotes {
    fill: $gray-light;
  }

  figcaption {
    margin-top: $padding-xs;
  }
}

// Blockquotes
blockquote {
  padding: $padding-sm;

  img {
    float: left;
    border: 1px solid $gray-light;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
  }

  footer {
    color: $gray-darkish;
    font: italic 17px/22px $sans-serif;
    margin-top: 0.5rem;

    a {
      text-decoration: underline;
    }
  }
}

// Phrase tags
kbd {
  border: 1px solid currentColor;
  border-radius: $corner-radius;
  display: inline-block;
  font: 0.7778rem/1 $monospace;
  margin-bottom: 0.5rem;
  padding: 0.3em 0.6em 0.4em;
}

// Headers
.header-large {
  color: $white;
  font: normal 600 36px/1 $sans-serif;
  margin-bottom: $padding-sm;

  @media screen and (min-width: $breakpoint-small) {
    font: normal 600 55px/1 $sans-serif;
    margin-bottom: $padding-sm;
  }

  &-long {
    font-size: 1.8em;

    @media screen and (min-width: $breakpoint-small) {
      font: normal 600 3em $sans-serif;
    }
  }
}

.header-medium {
  font: normal 700 35px/35px $sans-serif;
  margin-bottom: 20px;

  @media screen and (min-width: $breakpoint-small) {
    margin-bottom: $padding-md;
  }
}

.header-small {
  font: normal 700 22px/28px $sans-serif;
}

.header-uppercase {
  text-transform: uppercase;
}

// Sizing
.text-small {
  font-size: 17px;
}

.text-tiny {
  font-size: 14px;
  line-height: 20px;
}

// Alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media screen and (max-width: $breakpoint-small - 1) {
  .text-center-small {
    text-align: center !important;
  }
}

@media print, screen and (min-width: $breakpoint-large) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

// Colors
.text-gray {
  color: $gray;
}

.text-green {
  color: $green-brand;
}

.text-red {
  color: $red-dark;
}

.timezone,
.hint {
  font-family: $sans-serif;
  font-size: 0.7em;
  font-weight: normal;
  line-height: 1.6em;
}
